import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from './question-base';
import { mkNode } from './utils';
import { init, PellElement } from 'pell';
import { translate } from './utils-lang';

export class NoteViewer {
    private controlPanel: ControlPanel;
    private notesIconSpan: HTMLSpanElement;
    private notesText: Text;
    private notesButton: HTMLButtonElement;
    private notesPanel: HTMLDivElement;
    private notesArea: HTMLDivElement;
    private editor: HTMLDivElement & PellElement;
    private gripLines: HTMLDivElement;

    public constructor(
        controlPanel: ControlPanel,
        onChange: (notes: string) => void,

    ) {
        this.controlPanel = controlPanel;
        this.notesIconSpan = mkNode('icon', {icon: faEdit});
        this.notesText = mkNode('text', {text: translate('CONTROL_NOTES')})
        this.notesButton = mkNode('button', {
            className: 'app-button config-primary config-primary-hover config-primary-pressed config-primary-pressed-hover config-primary-fg-shadow-focus',
            attrib: {disabled: 'true'},
            children: [
                this.notesIconSpan,
                mkNode('span', {
                    className: 'app-button-text', children: [
                        this.notesText
                    ]
                })
            ]
        });
        this.controlPanel.add(this.notesButton);
        this.notesPanel = mkNode('div', { className: 'notes-bar', parent: this.controlPanel.panel()});
        this.notesArea = mkNode('div', {
            className: 'pell notes-text config-primary config-primary-fg-border config-primary-fg-shadow-focus', parent: this.notesPanel,
        });
        this.gripLines = mkNode('div', {className: 'vsize-grip', attrib: {hidden: 'true'}, parent: this.notesPanel});
        this.notesPanel.hidden = true;
        this.notesButton.disabled = false;

        this.editor = init({
            element: this.notesArea,
            onChange: html => onChange(html),
            actions: [
                'bold',
                'italic',
                'underline',
                'strikethrough',
                'heading1',
                'heading2',
                'quote',
                'olist',
                'ulist',
                'code',
                'line',
            ],
            classes: {
                content: 'pell-content config-background-highlight config-background-text',
                button: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                selected: 'config-primary-pressed-true',
            }
        });

        this.notesButton.addEventListener('click', this.handleClick);
        this.gripLines.addEventListener('mousedown', this.handleGripDown);
        window.addEventListener('mousemove', this.handleGripMove);
        window.addEventListener('mouseup', this.handleGripUp);
    }

    private readonly handleClick = () => {
        this.notesPanel.hidden = !this.notesPanel.hidden;
        this.notesButton.setAttribute('aria-pressed', String(!this.notesPanel.hidden));
        this.gripLines.hidden = this.notesPanel.hidden;
    }

    private gripped = false;
    private gripY = 0;

    private readonly handleGripDown = (event: MouseEvent) => {
        this.gripY = event.clientY;
        this.gripped = true;
    }

    private readonly handleGripMove = (event: MouseEvent) => {
        if (!this.gripped) {
            return;
        }
        const dy = event.clientY - this.gripY;
        const editable = this.notesArea.querySelector('div[contenteditable]');
        if (editable instanceof HTMLElement) {
            const h = editable.offsetHeight + dy;
            editable.style.height = h + 'px';
        }
        this.gripY = event.clientY;
    }

    private readonly handleGripUp = () => {
        if (!this.gripped) {
            return;
        }
        this.gripY = 0;
        this.gripped = false;
    }

    public destroy() {
        this.notesButton.removeEventListener('click', this.handleClick);
        this.gripLines.removeEventListener('mousedown', this.handleGripDown);
        window.removeEventListener('mousemove', this.handleGripMove);
        window.removeEventListener('mouseup', this.handleGripUp);
    }

    public load(notes?: string): void {
        this.editor.content.innerHTML = notes ?? '';
    }
}