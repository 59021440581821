import { faUserPlus, faUserMinus, faVideo, faMicrophone, faMicrophoneAlt, faMicrophoneAltSlash, faVolumeOff } from '@fortawesome/free-solid-svg-icons';
import { mkNode, removeChildren, replaceIcon, getPixelScale, removeNode, isIndexed, safeJsonParse} from './utils';
import { urlWithCredentials, httpDelete, getJson } from './utils-net';
import { ControlPanel } from './question-base';
import { ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration,
    MeetingSession, AudioVideoObserver, VideoTileState, MeetingSessionStatus, DeviceChangeObserver, EventName, EventAttributes, MeetingSessionStatusCode, AudioProfile, DataMessage, ContentShareObserver,
} from 'amazon-chime-sdk-js';
import { dbGet, dbPut } from 'utils-db';
import { translate } from 'utils-lang';
import { alertModal } from 'utils-progress';

interface Appointment {
    Meeting: unknown;
    Attendee: unknown;
    elapsedTime?: number;
    details?: {[id:string]:{role: string, component?: number}};
}

function isAppointment(x: unknown): x is Appointment {
    return x != null && isIndexed(x) &&
        'Meeting' in x &&
        'Attendee' in x &&
        (typeof x.elapsedTime === 'undefined' || typeof x.elapsedTime === 'number') &&
        (typeof x.details === 'undefined' || typeof x.details === 'object');
}

interface MeetingEventConnected {
    type: 'connectedRoles';
    roles: Map<string, number>;
}

interface MeetingEventMessage {
    type: 'message';
    html?: string;
}

//interface MeetingEventConnectionTime {
//    type: 'connectionTime';
//    round: number;
//    connectionTime: number;
//}

export type MeetingEvent = MeetingEventConnected | MeetingEventConnectionTime | MeetingEventMessage;

interface SetResourceStatus {
    type: 'setStatus';
    status: {id: string, released: boolean}[];
}

interface GetResourceStatus {
    type: 'getStatus';
}

interface ConnectionTime {
    type: 'connectionTime';
    round: number;
    connectionTime: number;
}

type MeetingEventConnectionTime = ConnectionTime;

//type PeerMessage = ResourceStatus | ConnectionTime;

/*
function isRelease(x: unknown): x is ResourceRelease {
    return isIndexed(x) && typeof x.type === 'string' && typeof x.resource === 'string' && x.type === 'release';
}

function isStatus(x: unknown): x is {id: string, released: boolean} {
    return isIndexed(x) && typeof x.id === 'string' && typeof x.released === 'boolean';
}
*/

function isSetStatus(x: unknown): x is SetResourceStatus {
    return isIndexed(x) && typeof x.type === 'string' && x.type === 'setStatus' && Array.isArray(x.status);
}

function isGetStatus(x: unknown): x is GetResourceStatus {
    return isIndexed(x) && typeof x.type === 'string' && x.type === 'getStatus';
}

function isConnectionTime(x: unknown): x is ConnectionTime {
    return isIndexed(x) && typeof x.type === 'string' && x.type === 'connectionTime' && typeof x.connectionTime === 'number' && typeof x.round === 'number';
}

export interface MeetingEventObserver {
    handleMeetingEvent(event: MeetingEvent): Promise<void>;
    getResourceStatus(status: {id: string, released: boolean}[]): {id: string, released: boolean}[];
    setResourceStatus(status?: {id: string, released: boolean}): void;
}

export enum ConnectionStatus {
    Disconnecting,
    Disconnected,
    Connecting,
    Connected,
    Error,
}

export enum ComponentDetails {
    ROLE_FLOORMARSHAL = 1,
    ROLE_EXAMINER = 2,
    ROLE_CANDIDATE = 3,
    ROLE_ADMIN = 4,
    ROLE_ROLEPLAYER = 5,
    ROLE_MARKER = 6,
    ROLE_OBSERVER = 7
}

export class MeetingViewer implements AudioVideoObserver, DeviceChangeObserver, ContentShareObserver {
    private controlPanel: ControlPanel;
    private meetingPanel: HTMLElement;
    private deviceBar: HTMLElement;
    private meetingBar: HTMLElement;
    public connectButton: HTMLButtonElement;
    private videoInButton?: HTMLButtonElement;
    private audioInButton?: HTMLButtonElement;
    private audioOutButton?: HTMLButtonElement;
    private muteButton?: HTMLButtonElement;
    private muteText?: HTMLElement;
    private muteIcon?: HTMLElement;
    private connectText: Text;
    private connectIconSpan: HTMLSpanElement;
    private audio: HTMLAudioElement;
    private callTimer?: HTMLDivElement;
    private interval?: number;

    private video: Map<number, {container: HTMLDivElement, element: HTMLVideoElement, text: HTMLDivElement}> = new Map();
    private connected = ConnectionStatus.Disconnected;
    private meetingSession?: MeetingSession;
    private examId?: string;
    private candidateId?: string;
    private interviewId?: number;
    private setNavigating: (s: boolean) => void;
    private audioInputDevices: MediaDeviceInfo[] = [];
    private audioOutputDevices: MediaDeviceInfo[] = [];
    private videoInputDevices: MediaDeviceInfo[] = [];
    private selectedVideoInput = '';
    private selectedAudioInput = '';
    private selectedAudioOutput = '';
    private videoInOpen = false;
    private audioInOpen = false;
    private audioOutOpen = false;
    private attendeePresenceSet = new Set();
    private eventObserver: MeetingEventObserver;
    //private startTime = new Date().getTime() / 1000;
    private attendeeDetails: {[id:string]:{role:string,component?:number}} = {};
    private component?: number;
    private fullscreenParent: HTMLElement;
    private pixelScale: {x: number, y: number};
    private gripLines: HTMLDivElement;
    private disableScreensharing: boolean;

    public getPresent(): number {
        return this.attendeePresenceSet.size;
    }

    private setConnecting(): void {
        this.connectText.textContent = translate('CONTROL_CONNECTING');
        this.connected = ConnectionStatus.Connecting;
        this.connectButton.disabled = true;
    }

    private setConnected(): void {
        this.connectText.textContent = translate('CONTROL_DISCONNECT');
        replaceIcon(this.connectIconSpan, faUserMinus);
        this.connected = ConnectionStatus.Connected;
        this.connectButton.disabled = false;
    }

    private setDisconnecting(): void {
        this.connectText.textContent = translate('CONTROL_DISCONNECTING');
        this.connected = ConnectionStatus.Disconnecting;
        this.connectButton.disabled = true;
    }

    private setDisconnected(): void {
        this.connectText.textContent = translate('CONTROL_CONNECT');
        replaceIcon(this.connectIconSpan, faUserPlus);
        this.connected = ConnectionStatus.Disconnected;
        this.connectButton.disabled = false;
    }

    private setConnectionError(): void {
        this.connectText.textContent = translate('CONTROL_CONNECT');
        replaceIcon(this.connectIconSpan, faUserPlus);
        this.connected = ConnectionStatus.Error;
        this.connectButton.disabled = false;
    }

    public getConnected(): ConnectionStatus {
        return this.connected;
    }

    public isConnected(): boolean {
        return this.connected == ConnectionStatus.Connected;
    }

    private alohaVideoInButton(show: boolean): void {
        if ((!show || this.videoInputDevices.length < 2) && this.videoInButton) {
            this.controlPanel.remove(this.videoInButton);
            this.videoInButton.removeEventListener('click', this.handleVideoIn);
            this.videoInButton = undefined;
        } else if (show && !this.videoInButton && this.videoInputDevices.length > 1) {
            this.videoInButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    mkNode('icon', {icon: faVideo}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: translate('CONTROL_CAMERA')})
                        ]
                    })
                ]
            });
            if (this.videoInButton) {
                this.controlPanel.add(this.videoInButton);
                this.videoInButton.addEventListener('click', this.handleVideoIn);
            }
        }
    }

    private alohaAudioInButton(show: boolean): void {
        if ((!show || this.audioInputDevices.length < 2) && this.audioInButton) {
            this.controlPanel.remove(this.audioInButton);
            this.audioInButton.removeEventListener('click', this.handleAudioIn);
            this.audioInButton = undefined;
        } else if (show && !this.audioInButton && this.audioInputDevices.length > 1) {
            this.audioInButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    mkNode('icon', {icon: faMicrophone}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: translate('CONTROL_MICROPHONE')})
                        ]
                    })
                ]
            });
            if (this.audioInButton) {
                this.controlPanel.add(this.audioInButton);
                this.audioInButton.addEventListener('click', this.handleAudioIn);
            }
        }
    }

    private alohaAudioOutButton(show: boolean): void {
        if ((!show || this.audioOutputDevices.length < 2) && this.audioOutButton) {
            this.controlPanel.remove(this.audioOutButton);
            this.audioOutButton.removeEventListener('click', this.handleAudioOut);
            this.audioOutButton = undefined;
        } else if (show && !this.audioOutButton && this.audioOutputDevices.length > 1) {
            this.audioOutButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    mkNode('icon', {icon: faVolumeOff}),
                    mkNode('span', {
                        className: 'app-button-text', children: [
                            mkNode('text', {text: translate('CONTROL_SPEAKERS')})
                        ]
                    })
                ]
            });
            if (this.audioOutButton) {
                this.controlPanel.add(this.audioOutButton);
                this.audioOutButton.addEventListener('click', this.handleAudioOut);
            }
        }
    }

    private alohaMuteButton(show: boolean): void {
        if (!show && this.muteButton) {
            this.controlPanel.remove(this.muteButton);
            this.muteButton.removeEventListener('click', this.handleMute);
            this.muteButton = undefined;
        } else if (show && !this.muteButton) {
            this.muteText = mkNode('span', {
                className: 'app-button-text', children: [
                    mkNode('text', {text: translate('CONTROL_MUTE')})
                ]
            });
            this.muteIcon = mkNode('icon', {icon: faMicrophoneAlt});
            this.muteButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    this.muteIcon,
                    this.muteText,
                ]
            });
            if (this.muteButton) {
                this.controlPanel.add(this.muteButton);
                this.muteButton.addEventListener('click', this.handleMute);
            }
        }
    }

    private readonly handleMute = async (): Promise<void> => {
        try {
            if (this.meetingSession) {
                if (this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()) {
                    const unmuted = this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
                    if (unmuted && this.muteButton && this.muteText && this.muteIcon) {
                        this.muteText.textContent = translate('CONTROL_MUTE');
                        const icon = mkNode('icon', {icon: faMicrophoneAlt});
                        this.muteButton.replaceChild(icon, this.muteIcon);
                        this.muteIcon = icon;
                    }
                } else {
                    this.meetingSession.audioVideo.realtimeMuteLocalAudio();
                    if (this.muteButton && this.muteText && this.muteIcon) {
                        this.muteText.textContent = translate('CONTROL_UNMUTE');
                        const icon = mkNode('icon', {icon: faMicrophoneAltSlash});
                        this.muteButton.replaceChild(icon, this.muteIcon);
                        this.muteIcon = icon;
                    }
                }
            }
        } catch (err) {
            console.error('HANDLE_MUTE', String(err));
            alertModal(`Mute/Unmute error: ${String(err)}`);
        }
    }

    public constructor(
        setNavigating: (a: boolean) => void,
        controlPanel: ControlPanel,
        meetingBar: HTMLElement,
        examId: string,
        candidateId: string,
        eventObserver: MeetingEventObserver,
        //interviewId: number,
        fullscreenParent: HTMLElement,
        disableScreensharing: boolean,
        component?: number,

    ) {
        this.eventObserver = eventObserver;
        this.controlPanel = controlPanel;
        this.meetingPanel = meetingBar;
        this.disableScreensharing = disableScreensharing;
        this.component = component;
        this.fullscreenParent = fullscreenParent;
        this.deviceBar = mkNode('div', {className: 'sub-control', parent: this.meetingPanel});
        this.meetingBar = mkNode('div', {className: 'meeting-bar', attrib: {hidden: 'true'}, parent: this.meetingPanel});
        this.gripLines = mkNode('div', {className: 'vsize-grip', attrib: {hidden: 'true'}, parent: this.meetingPanel});
        this.audio = mkNode('audio', {
            parent: this.meetingBar
        });
        this.connectIconSpan = mkNode('span', {children: [
            mkNode('icon', {icon: faUserPlus})
        ]});
        this.connectText = mkNode('text', {text: translate('CONTROL_CONNECT')})
        this.connectButton = mkNode('button', {
            className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
            attrib: {disabled: 'true'},
            children: [
                this.connectIconSpan,
                mkNode('span', {
                    className: 'app-button-text', children: [
                        this.connectText
                    ]
                })
            ]
        });
        this.controlPanel.add(this.connectButton);
        this.examId = examId;
        this.candidateId = candidateId;
        //this.interviewId = interviewId;
        this.pixelScale = getPixelScale();
        this.setNavigating = setNavigating;
        //window.addEventListener('resize', this.handleWindowResize);
        this.gripLines.addEventListener('mousedown', this.handleGripDown);
        window.addEventListener('mousemove', this.handleGripMove);
        window.addEventListener('mouseup', this.handleGripUp);
    }

    private videoHeight = 120;
    private gripped = false;
    private gripY = 0;

    private readonly handleGripDown = (event: MouseEvent) => {
        this.gripY = event.clientY;
        this.gripped = true;
    }

    private readonly handleGripMove = (event: MouseEvent) => {
        if (!this.gripped) {
            return;
        }
        const dy = event.clientY - this.gripY;
        let h = this.videoHeight + dy;
        //let w = Array.from(this.video.values()).reduce((acc, v) => acc + v.element.offsetWidth, 0);
        //w += dy / h * w;
        if (h < 64) {
            h = 64;
        }
        this.video.forEach(videoTile => {
            videoTile.element.style.minHeight = h + 'px';
            videoTile.element.style.maxHeight = h + 'px';
        });
        this.gripY = event.clientY;
        this.videoHeight = h;
    }

    private readonly handleGripUp = () => {
        if (!this.gripped) {
            return;
        }
        this.gripY = 0;
        this.gripped = false;
    }

    private async bestAudioInput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]): Promise<void> {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'audio-in');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => d.deviceId === 'communications')?.deviceId
                    || mediaDevices.find(d => d.deviceId === 'default')?.deviceId
                    || mediaDevices[0].deviceId;
            }
            await meetingSession.audioVideo.chooseAudioInputDevice(pick);
            this.selectedAudioInput = pick;
            this.audioInputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.audioInputDevices = [];
        }
    }

    private async bestAudioOutput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]) {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'audio-out');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => d.deviceId === 'communications')?.deviceId
                    || mediaDevices.find(d => d.deviceId === 'default')?.deviceId
                    || mediaDevices[0].deviceId;
            }
            await meetingSession.audioVideo.chooseAudioOutputDevice(pick);
            this.selectedAudioOutput = pick;
            this.audioOutputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.audioOutputDevices = [];
        }
    }

    private async bestVideoInput(meetingSession: MeetingSession, mediaDevices: MediaDeviceInfo[]) {
        if (mediaDevices.length > 0) {
            const savedPick = await dbGet('session', 'video-in');
            let pick = null;
            if (typeof savedPick === 'string') {
                pick = mediaDevices.find(d => d.deviceId === savedPick)?.deviceId;
            }
            if (!pick) {
                pick = mediaDevices.find(d => /front/.test(d.label.toLocaleLowerCase()))?.deviceId
                    || mediaDevices[0].deviceId;
            }
            await meetingSession.audioVideo.chooseVideoInputDevice(pick);
            this.selectedVideoInput = pick;
            this.videoInputDevices = mediaDevices.filter(ai => ai.deviceId !== 'default' && ai.deviceId !== 'communications');
        } else {
            this.videoInputDevices = [];
        }
    }

    public async getAppointment(): Promise<Appointment> {
        const appointment = await getJson(urlWithCredentials(
            '/app/' + this.examId +
            '/appointment/' + this.interviewId + '/' + this.candidateId + '/'
        ));
        //console.log('APPOINTMENT', appointment);
        if (!isAppointment(appointment)) {
            console.error('APPOINTMENT', appointment);
            throw new TypeError('Server returned invalid Appointment.');
        }
        //this.startTime = new Date().getTime() / 1000.0 - (appointment.elapsedTime ?? 0);
        this.attendeeDetails = appointment.details ?? {};
        console.log('APPOINTMENT:', appointment);
        return appointment;
    }

    public async startMeeting(): Promise<void> {
        if (this.examId === undefined || this.interviewId === undefined || this.candidateId === undefined) {
            return;
        }
        const appointment = await this.getAppointment();

        try {
            this.setNavigating(true);
            this.setConnecting();

            this.logDevices();

            const logger = new ConsoleLogger('MeetingLogger', LogLevel.WARN);
            const deviceController = new DefaultDeviceController(logger);

            // No point in getting higher resolution with current tile size.
            // deviceController.chooseVideoInputQuality(640, 360, 30, 800);
            const configuration = new MeetingSessionConfiguration(appointment.Meeting, appointment.Attendee);
            this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
            this.meetingSession.audioVideo.setAudioProfile(AudioProfile.fullbandSpeechMono());
            this.meetingSession.audioVideo.addDeviceChangeObserver(this);

            //this.meetingSession.audioVideo.setDeviceLabelTrigger(async () => new MediaStream());
            //this.meetingSession.audioVideo.addObserver(this);
            //this.meetingSession.audioVideo.start();
            //this.meetingSession.audioVideo.setDeviceLabelTrigger(async () =>
            //    await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            //);

            try {
                const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
                audioInputDevices.forEach(mediaDeviceInfo => {
                    console.log(`Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`);
                });

                await this.bestAudioInput(this.meetingSession, audioInputDevices);
                console.debug(`AUDIO IN : ${this.selectedAudioInput}`);
            } catch(err) {
                console.error('SELECT_AUDIO_IN', err);
            }

            try {
                const audioOutputDevices = await this.meetingSession.audioVideo.listAudioOutputDevices();
                audioOutputDevices.forEach(mediaDeviceInfo => {
                    console.log(`Device ID: ${mediaDeviceInfo.deviceId} Audio: ${mediaDeviceInfo.label}`);
                });

                await this.bestAudioOutput(this.meetingSession, audioOutputDevices);
                console.debug(`AUDIO OUT: ${this.selectedAudioOutput}`);
            } catch(err) {
                console.error('SELECT_AUDIO_OUT', err);
            }

            try {
                await this.meetingSession.audioVideo.bindAudioElement(this.audio);
            } catch (err) {
                console.error('BIND_AUDIO', err);
            }

            if (this.component !== ComponentDetails.ROLE_OBSERVER) {
                await this.bestVideoInput(this.meetingSession, await this.meetingSession.audioVideo.listVideoInputDevices());
            }

            this.alohaMuteButton(true);
            this.alohaAudioInButton(true);
            this.alohaAudioOutButton(true);
            this.alohaVideoInButton(true);

            console.warn('SUBSCRIBE TO RECEIVE DATA MESSAGE');
            this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('resources', this.handleResourceMessage);
            this.meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage('connectionTime', this.handleConnectionTimeMessage);
            this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.handlePresence);
            this.meetingSession.audioVideo.addContentShareObserver(this);
            this.meetingSession.audioVideo.addObserver(this);
            this.meetingSession.audioVideo.start();
            if (!this.disableScreensharing && this.component === ComponentDetails.ROLE_CANDIDATE) {
                try {
                    await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
                } catch(err) {
                    if (isIndexed(err) && err.name === 'InvalidAccessError') {
                        const meetingSession = this.meetingSession;
                        await alertModal(translate('SCREEN_SHARE_PERMISSION'), async () => {
                            await meetingSession.audioVideo.startContentShareFromScreenCapture();
                        })
                    } else {
                        console.error(String(err));
                    }
                }
            }

            // signal strength
            if (this.component !== ComponentDetails.ROLE_OBSERVER) {
                this.meetingSession.audioVideo.startLocalVideoTile();
            } else {
                this.handleMute();
            }
        } catch (err) {
            this.setConnectionError();
            this.setNavigating(false);
            throw (err);
        }
    }

    private async logDevices(): Promise<void> {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("enumerateDevices() not supported.");
            return;
        }

        try {
            const devices = await navigator.mediaDevices.enumerateDevices();

            for (const device of devices) {
                console.log(`${device.kind}: ${device.label} id = ${device.deviceId}`);
            }
        } catch(err) {
            console.error(String(err));
        }
    }

    public contentShareDidStart(): void {
        console.log('Screen share started');
    }

    public contentShareDidStop(): void {
        console.log('Screen share stopped');
    }

    public connectionDidBecomeGood(): void {
        //this.eventObserver.handleMeetingEvent({type: 'message'});
    }

    public connectionDidBecomePoor(): void {
        alertModal(translate('MEETING_POOR_CONNECTION'));
        //this.eventObserver.handleMeetingEvent({type: 'message', html: translate('MEETING_POOR_CONNECTION')});
    }

    public connectionDidSuggestStopVideo(): void {
        alertModal(translate('MEETING_POOR_CONNECTION'));
        //this.eventObserver.handleMeetingEvent({type: 'message', html: translate('MEETING_POOR_CONNECTION')});
    }

    /*
    public videoNotReceivingEnoughData(): void {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_POOR_DOWN_CONNECTION'));
    }

    public metricsDidReceive(clientMetricReport: ClientMetricReport): void {
        console.warn('METRICS', clientMetricReport);
    }
    */

    /* FOR LIMIT OF 16 VIDEO STREAMS
    public readonly videoSendDidBecomeUnavailable = ():void => {
        this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_NO_VIDEO'));
    }

    public readonly videoAvailabilityDidChange = (videoAvailability: MeetingSessionVideoAvailability): void => {
        if (videoAvailability.canStartLocalVideo) {
            this.eventObserver.handleMeetingEvent(MeetingEvent.Message);
        } else {
            this.eventObserver.handleMeetingEvent(MeetingEvent.Message, translate('MEETING_NO_VIDEO'));
        }
    }
    */

    private readonly handleResourceMessage = (dataMessage: DataMessage): void => {
        try {
            const enc = new TextDecoder('utf-8');
            const json = enc.decode(dataMessage.data);
            console.warn('JSON_MESSAGE', json);
            const message = safeJsonParse(json);
            if (isGetStatus(message)) {
                this.sendStatus();
            } else if (isSetStatus(message)) {
                for (const status of message.status) {
                    this.eventObserver.setResourceStatus(status);
                }
            }
        } catch (err) {
            console.error('HANDLE_RESOURCE_MESSAGE', String(err));
            alertModal(`Handle resource Lock/Unlock error: ${String(err)}`);
        }
    }

    private readonly handleConnectionTimeMessage = (dataMessage: DataMessage): void => {
        try {
            const enc = new TextDecoder('utf-8');
            const json = enc.decode(dataMessage.data);
            console.warn('JSON_MESSAGE', json);
            const message = safeJsonParse(json);
            if (isConnectionTime(message)) {
                this.eventObserver.handleMeetingEvent(message);
            }
        } catch (err) {
            console.error('HANDLE_CONNECTION_TIME_MESSAGE', String(err));
            alertModal(`Handle connection time error: ${String(err)}`);
        }
    }

    public sendStatus(status: {id: string, released: boolean}[] = this.eventObserver.getResourceStatus([])): void {
        console.warn('SEND DATA MESSAGE', status);
        this.meetingSession?.audioVideo.realtimeSendDataMessage('resources', JSON.stringify({type: 'setStatus', status}));
    }

    public requestStatus(): void {
        console.warn('SEND DATA MESSAGE requestStatus');
        this.meetingSession?.audioVideo.realtimeSendDataMessage('resources', JSON.stringify({type: 'getStatus'}));
    }

    public sendTime(data: ConnectionTime): void {
        console.warn('SEND DATA MESSAGE', data);
        this.meetingSession?.audioVideo.realtimeSendDataMessage('connectionTime', JSON.stringify(data));
    }

    private attendees = new Set<string>();

    private readonly handlePresence = async (presentAttendeeId: string, present: boolean, userId?: string): Promise<void> => {
        try {
            const oldSize = this.attendees.size;
            if (userId) {
                if (this.attendees.has(userId)) {
                    if (present === false) {
                        this.attendees.delete(userId);
                    }
                } else {
                    if (present === true) {
                        this.attendees.add(userId);
                    }
                }
            }
            const newSize = this.attendees.size;
            if (newSize !== oldSize) {
                await this.processMeetingEvent(this.getRoles());
            }
        } catch (err) {
            console.error('HANDLE_Presence', String(err));
            alertModal(`Handle presence error: ${String(err)}`);
        }
    };

    public getRoles(): Map<string, number> {
        const roles = new Map<string, number>();
        this.attendees.forEach(userId => {
            const attendeeDetails = this.attendeeDetails[userId];
            if (attendeeDetails) {
                const component = attendeeDetails.component;
                let role = attendeeDetails.role?.toLowerCase();
                switch (component) {
                    case ComponentDetails.ROLE_FLOORMARSHAL:
                        role = 'floormarshal';
                        break;
                    case ComponentDetails.ROLE_CANDIDATE:
                        role = 'candidate';
                        break;
                    case ComponentDetails.ROLE_EXAMINER:
                        role = 'examiner';
                        break;
                    case ComponentDetails.ROLE_ADMIN:
                        role = 'admin';
                        break;
                    case ComponentDetails.ROLE_ROLEPLAYER:
                        role = 'roleplayer';
                        break;
                    case ComponentDetails.ROLE_MARKER:
                        role = 'marker';
                        break;
                    case ComponentDetails.ROLE_OBSERVER:
                        role = 'observer';
                        break;
                    default:
                        if (role) {
                            if (role.indexOf('examiner') !== -1) {
                                role = 'examiner';
                            } else if (role.indexOf('candidate') !== -1) {
                                role = 'candidate';
                            }
                        }
                        break;
                }
                if (role) {
                    const count = roles.get(role);
                    roles.set(role, (count === undefined) ? 1 : count + 1);
                }
            }
        });
        return roles;
    }

    private async processMeetingEvent(roles = new Map<string, number>()) {
        await this.eventObserver.handleMeetingEvent({type: 'connectedRoles', roles});
    }

    public async audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): Promise<void> {
        removeChildren(this.deviceBar);
        if (this.meetingSession) {
            await this.bestAudioInput(this.meetingSession, freshAudioInputDeviceList);
        }
        this.alohaAudioInButton(true);
        if (this.audioInOpen) {
            this.showDeviceList('Choose which microphone to use:', this.audioInputDevices, this.selectedAudioInput, this.chooseAudioInputDevice);
        }
    }

    public async audioOutputsChanged(freshAudioOutputDeviceList: MediaDeviceInfo[]): Promise<void> {
        removeChildren(this.deviceBar);
        if (this.meetingSession) {
            await this.bestAudioOutput(this.meetingSession, freshAudioOutputDeviceList);
        }
        this.alohaAudioOutButton(true);
        if (this.audioOutOpen) {
            this.showDeviceList('Choose which speakers/headset to use:', this.audioOutputDevices, this.selectedAudioOutput, this.chooseAudioOutputDevice);
        }
    }

    public async videoInputsChanged(freshVideoInputDeviceList: MediaDeviceInfo[]): Promise<void> {
        if (this.component !== ComponentDetails.ROLE_OBSERVER) {
            removeChildren(this.deviceBar);
            if (this.meetingSession) {
                await this.bestVideoInput(this.meetingSession, freshVideoInputDeviceList);
            }
            this.alohaVideoInButton(true);
            if (this.videoInOpen) {
                this.showDeviceList('Choose which camera to use:', this.videoInputDevices, this.selectedVideoInput, this.chooseVideoInputDevice);
            }
        }
    }

    public audioVideoDidStart(): void {
        console.log('AUDIO_VIDEO_DID_START');
        //if (this.meetingSession) {
        //    this.meetingSession.audioVideo.startLocalVideoTile();
        //}
        this.setConnected();
        this.meetingBar.setAttribute('hidden', 'false');
        this.gripLines.setAttribute('hidden', 'false');
        this.setNavigating(false);
        //this.sendStatus();
    }

    public audioVideoDidStartConnecting(reconnecting: boolean): void {
        if (reconnecting) {
            this.setConnecting();
        }
    }

    public async audioVideoDidStop(sessionStatus: MeetingSessionStatus): Promise<void> {
        const statusCode = sessionStatus.statusCode();
        console.warn('AUDIO_VIDEO_DID_STOP', MeetingSessionStatusCode[statusCode]);
        console.warn('FAILURE', sessionStatus.isFailure());
        console.warn('TERMINAL', sessionStatus.isTerminal());
        console.warn('AUDIO_CONN_FAIL', sessionStatus.isAudioConnectionFailure());
        switch (statusCode) {
            case MeetingSessionStatusCode.Left:
            case MeetingSessionStatusCode.MeetingEnded:
                break;
            default:
                alertModal(translate('MEETING_STATUS_ERROR', {err: MeetingSessionStatusCode[statusCode]}));
                break;
        }
        if (this.meetingSession) {
            this.meetingSession.audioVideo.realtimeUnsubscribeFromReceiveDataMessage('resources');
            this.meetingSession.audioVideo.realtimeUnsubscribeFromReceiveDataMessage('connectionTime');
            this.meetingSession.audioVideo.realtimeUnsubscribeToAttendeeIdPresence(this.handlePresence);
            this.meetingSession.audioVideo.removeObserver(this);
            this.meetingSession.audioVideo.removeDeviceChangeObserver(this);
        }
        this.gripLines.setAttribute('hidden', 'true');
        this.meetingBar.setAttribute('hidden', 'true');
        removeChildren(this.deviceBar);
        //this.alohaTimer(false);
        this.alohaAudioInButton(false);
        this.alohaAudioOutButton(false);
        this.alohaVideoInButton(false);
        this.alohaMuteButton(false);
        this.attendees.clear();
        await this.eventObserver.handleMeetingEvent({type: 'connectedRoles', roles: new Map<string, number>()});
        this.attendeePresenceSet.clear();
        try {
            await httpDelete(urlWithCredentials(
                '/app/' + this.examId +
                '/appointment/' + this.interviewId + '/' + this.candidateId + '/'
            ));
        } catch (err) {
            console.error('DELETING INTERVIEW: ', err);
        }
        if (this.onStopped) {
            const onStopped = this.onStopped;
            this.onStopped = undefined;
            onStopped();
        }
        this.setDisconnected();
        this.setNavigating(false);
    }

    //private tileCounter = 0;

    public videoTileDidUpdate(tileState: VideoTileState): void {
        console.log('VIDEO_TILE_DID_UPDATE', tileState);
        const myAttendeeId = this.meetingSession?.configuration.credentials?.attendeeId;
        if (tileState.tileId != null && this.meetingSession && tileState.boundAttendeeId && tileState.boundAttendeeId !== myAttendeeId + '#content') {
            let videoTile = this.video.get(tileState.tileId);
            if (!videoTile) {
                const container = mkNode('div', {className: 'video-tile config-primary' /*, attrib: {order: (this.tileCounter++).toString()}*/});
                const element = mkNode('video', {
                    parent: container,
                    className: 'video-element',
                    style: {visibility: 'visible'},
                    attrib: {show: 'true', playsinline: 'true'}
                });
                const text = mkNode('div', {className: 'video-caption', parent: container});
                videoTile = {container, element, text};
                if (this.callTimer) {
                    this.meetingBar.insertBefore(videoTile.container, this.callTimer);
                } else {
                    this.meetingBar.appendChild(videoTile.container);
                }
                videoTile.element.style.display = 'block';
                videoTile.element.style.minHeight = this.videoHeight + 'px';
                videoTile.element.style.maxHeight = this.videoHeight + 'px';
                videoTile.element.addEventListener('resize', this.videoResizeHandler);
                videoTile.element.addEventListener('dblclick', this.videoDblClickHandler);
                this.video.set(tileState.tileId, videoTile);
            }
            const userId = tileState.boundExternalUserId;
            if (userId) {
                videoTile.text.textContent = (this.attendeeDetails[userId]?.role ?? userId).toUpperCase();
            }
            this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoTile.element);
        }
    }

    private readonly videoResizeHandler = (ev: Event) => {
        ev.target?.removeEventListener('resize', this.videoResizeHandler);
        setTimeout(() => {
            const w = Array.from(this.video.values()).reduce((acc, v) => acc + v.element.offsetWidth, 0);
            if (w > this.meetingBar.clientWidth) {
                this.videoHeight = this.videoHeight * this.meetingBar.clientWidth / w;
                this.video.forEach(videoTile => {
                    videoTile.element.style.minHeight = this.videoHeight + 'px';
                    videoTile.element.style.maxHeight = this.videoHeight + 'px';
                });
            }
        }, 1000);
    };

    public videoTileWasRemoved(tileId: number): void {
        console.log('VIDEO_TILE_WAS_REMOVED', tileId);
        const videoTile = this.video.get(tileId);
        if (videoTile) {
            videoTile.element.removeEventListener('dblclick', this.videoDblClickHandler);
            videoTile.element.removeEventListener('resize', this.videoResizeHandler);
            this.meetingBar.removeChild(videoTile.container);
            this.video.delete(tileId);
        }
    }

    private originalParent?: HTMLElement;
    private originalNextSibling?: ChildNode;

    private readonly videoDblClickHandler = async (event: MouseEvent) => {
        if (event?.target instanceof HTMLElement) {
            const parent = event.target.parentElement;
            if (parent) {
                if (this.originalParent) {
                    this.originalParent.insertBefore(parent, this.originalNextSibling ?? null);
                    parent.className = 'video-tile';
                    event.target.style.minHeight = this.videoHeight + 'px'
                    event.target.style.maxHeight = this.videoHeight + 'px';
                    event.target.style.width='';
                    event.target.style.height='';
                    this.originalParent = undefined;
                    this.originalNextSibling = undefined;
                } else {
                    this.originalParent = parent?.parentElement ?? undefined;
                    this.originalNextSibling = parent?.nextSibling ?? undefined;
                    this.fullscreenParent.insertBefore(parent, this.fullscreenParent.firstChild);
                    parent.className = 'video-tile-fullscreen';
                    event.target.style.minHeight = '';
                    event.target.style.maxHeight = '';
                    event.target.style.width='100%';
                    event.target.style.height='100%';
                }
            }
        }
    }

    private readonly handleConnect = async (): Promise<void> => {
        try {
            switch (this.connected) {
                case ConnectionStatus.Disconnected:
                case ConnectionStatus.Error:
                    await this.startMeeting();
                    break;
                case ConnectionStatus.Connected:
                    await this.stopMeeting();
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error('HANDLE_CONNECT', String(err));
            alertModal(`Connection/Disconnection error: ${String(err)}`);
        }
    }

    private showDeviceList(help: string, devices: MediaDeviceInfo[], selectedId: string, selectDevice: (device: MediaDeviceInfo) => Promise<void>): void {
        mkNode('div', {parent: this.deviceBar, className: 'device-bar', children: [
            mkNode('text', {text: help}),
        ]});
        const deviceBar = mkNode('div', {parent: this.deviceBar, className: 'meetingBar'});
        for (const dev of devices) {
            //console.log('COMPARE:', dev.deviceId, selectedId);
            const button = mkNode('button', {
                parent: deviceBar,
                className: 'app-button config-primary-hover',
                children: [
                    mkNode('span', {
                        attrib: {style: 'font-weight:' + ((dev.deviceId == selectedId) ? 'bold' : 'normal')},
                        children: [
                            mkNode('text', {text: dev.label})
                        ]
                    })
                ]
            });
            button.addEventListener('click', async () => selectDevice(dev));
        }
    }

    private readonly chooseVideoInputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            try {
                await this.meetingSession.audioVideo.chooseVideoInputDevice(device.deviceId);
                removeChildren(this.deviceBar);
                this.videoInOpen = false;
                this.selectedVideoInput = device.deviceId;
                await dbPut('session', 'video-in', device.deviceId);
            } catch (err) {
                console.error('CHOOSE_VIDEO_IN', String(err));
                alertModal(`Cannot select camera: ${String(err)}`);
            }
        }
    }

    private readonly handleVideoIn = (): void => {
        try {
            removeChildren(this.deviceBar);
            this.audioInOpen = false;
            this.audioOutOpen = false;
            if (!this.videoInOpen) {
                this.videoInOpen = true;
                this.showDeviceList('Choose which camera to use:', this.videoInputDevices, this.selectedVideoInput, this.chooseVideoInputDevice);
            } else {
                this.videoInOpen = false;
            }
        } catch (err) {
            console.error('HANDLE_VIDEO_IN', String(err));
            alertModal(`Show camera list: ${String(err)}`);
        }
    }

    private readonly chooseAudioInputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            try {
                await this.meetingSession.audioVideo.chooseAudioInputDevice(device.deviceId);
                removeChildren(this.deviceBar);
                this.audioInOpen = false;
                this.selectedAudioInput = device.deviceId;
                await dbPut('session', 'audio-in', device.deviceId)
            } catch (err) {
                console.error('CHOOSE_AUDIO_IN', String(err));
                alertModal(`Cannot select microphone: ${String(err)}`);
            }
        }
    }

    private readonly handleAudioIn = (): void => {
        try {
            removeChildren(this.deviceBar);
            this.videoInOpen = false;
            this.audioOutOpen = false;
            if (!this.audioInOpen) {
                this.audioInOpen = true;
                this.showDeviceList('Choose which microphone to use:', this.audioInputDevices, this.selectedAudioInput, this.chooseAudioInputDevice);
            } else {
                this.audioInOpen = false;
            }
        } catch (err) {
            console.error('HANDLE_AUDIO_IN', String(err));
            alertModal(`Show microphone list: ${String(err)}`);
        }
    }

    private readonly chooseAudioOutputDevice = async (device: MediaDeviceInfo): Promise<void> => {
        if (this.meetingSession) {
            try {
                await this.meetingSession.audioVideo.chooseAudioOutputDevice(device.deviceId);
                removeChildren(this.deviceBar);
                this.audioOutOpen = false;
                this.selectedAudioOutput = device.deviceId;
                await dbPut('session', 'audio-out', device.deviceId);
            } catch (err) {
                console.error('CHOOSE_AUDIO_OUT', String(err));
                alertModal(`Cannot select speakers: ${String(err)}`);
            }
        }
    }

    private readonly handleAudioOut = (): void => {
        try {
            removeChildren(this.deviceBar);
            this.videoInOpen = false;
            this.audioInOpen = false;
            if (!this.audioOutOpen) {
                this.audioOutOpen = true;
                this.showDeviceList('Choose which speakers/headset to use:', this.audioOutputDevices, this.selectedAudioOutput, this.chooseAudioOutputDevice);
            } else {
                this.audioOutOpen = false;
            }
        } catch (err) {
            console.error('HANDLE_AUDIO_OUT', String(err));
            alertModal(`Show speaker list: ${String(err)}`);
        }
    }

    public enable(): void {
        this.connectButton.addEventListener('click', this.handleConnect);
        this.connectButton.disabled = false;
    }

    public disable(): void {
        this.connectButton.disabled = true;
        this.connectButton.removeEventListener('click', this.handleConnect);
    }

    public async setInterview(examId?: string, candidateId?: string, interviewId?: number): Promise<boolean> {
        if (examId !== this.examId || candidateId !== this.candidateId || interviewId !== this.interviewId) {
            await this.stopMeeting();
            this.examId = examId;
            this.candidateId = candidateId;
            this.interviewId = interviewId;
            if (examId !== undefined && candidateId !== undefined && interviewId !== undefined) {
                this.enable();
            } else {
                this.disable();
            }
            return true;
        } else {
            //await this.processMeetingEvent(this.getRoles());
            return false;
        }
    }

    /*
    public readonly handleWindowResize = (): void => {
        try {
            const y = calcTileMaxSize();
            this.video.forEach(videoTile => {
                videoTile.element.style.maxHeight = y + 'px';
            });
        } catch (err) {
            console.error('HANDLE_WINDOW_RESIZE', String(err));
            alertModal(`Window resize error: ${String(err)}`);
        }
    }
    */

    public eventDidReceive(name: EventName, attributes: EventAttributes): void {
        console.warn('EVENT', name);
        switch (name) {
            case 'audioInputFailed':
                alertModal(`Failed to choose microphone: ${attributes.audioInputErrorMessage}`);
                break;
            case 'videoInputFailed':
                alertModal(`Failed to choose camera: ${attributes.videoInputErrorMessage}`);
                break;
            case 'meetingStartFailed':
                alertModal(`Failed to start meeting: ${attributes.meetingErrorMessage}`);
                break;
            case 'meetingFailed':
                alertModal(`Failed during a meeting: ${attributes.meetingErrorMessage}`);
                break;
            case 'meetingEnded':
                alertModal(`Meeting Ended: ${attributes.meetingStatus}`);
                break;
            default:
                break;
        }
    }

    private onStopped?: () => void;

    public stopMeeting(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.connected === ConnectionStatus.Connected && this.meetingSession) {
                try {
                    this.setNavigating(true);
                    this.setDisconnecting();
                    this.eventObserver.setResourceStatus();
                    this.meetingSession.audioVideo.stopLocalVideoTile();
                    this.meetingSession.audioVideo.removeLocalVideoTile();
                    if (!this.disableScreensharing && this.component === ComponentDetails.ROLE_CANDIDATE) {
                        this.meetingSession.audioVideo.stopContentShare();
                    }
                    this.meetingSession.audioVideo.stop();
                    this.onStopped = resolve;
                } catch(err) {
                    this.setConnectionError();
                    this.setNavigating(false);
                    reject(err);
                }
            } else {
                resolve();
            }
        });
    }

    public async destroy(): Promise<void> {
        await this.stopMeeting();
        //window.removeEventListener('resize', this.handleWindowResize);
        this.gripLines.removeEventListener('mousedown', this.handleGripDown);
        window.removeEventListener('mousemove', this.handleGripMove);
        window.removeEventListener('mouseup', this.handleGripUp);
        this.gripLines.removeEventListener('mousedown', this.handleGripDown);
        this.connectButton.removeEventListener('click', this.handleConnect);
        this.controlPanel.remove(this.connectButton);
        removeNode(this.deviceBar);
        removeNode(this.meetingBar);
        removeNode(this.gripLines);
    }
}
