import { removeNode, mkNode, scrollRangeIntoView } from 'utils';
import { LocalData } from 'exam-service';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerKey, AnswerValue, QuestionArgs } from 'question-base';
import { Json } from 'exam-service';
import { Lightbox } from "lightbox";
import { configInvalidWarnButton, configSolidDngrButton, configSolidSafeButton } from 'exam-accessibility';

function isArrayOfString(x: unknown): x is Array<string> {
    return (x instanceof Array) && x.reduce((acc, y) => acc && (typeof y === 'string'), true);
}

class FieldOrder {
    public row: HTMLDivElement;
    public option: HTMLDivElement;
    public move: HTMLSelectElement;
    public optionIndex: number;

    public constructor(parent: HTMLElement, content: string, optionCount: number, optionIndex: number) {
        this.optionIndex = optionIndex;
        this.row = mkNode('div', { className: 'cpq-row' });
        this.option = mkNode('div', { className: 'cpq-option config-safe-border-empty config-warn-border-empty-invalid', parent: this.row, attrib: {'aria-disabled': 'true'}});
        this.option.innerHTML = '<div>' + content + '</div>';
        this.move = mkNode('select', { className: 'cpq-button ' + configSolidSafeButton + configInvalidWarnButton, parent: this.row, attrib: { disabled: 'disabled' } });
        this.move.disabled = true;
        const notans = mkNode('option', {
            parent: this.move, attrib: {
                selected: 'selected',
                disabled: 'disabled',
                //hidden: 'hidden',
                value: '-1',
            }
        });
        notans.innerHTML = '?';
        for (let i = 0; i < optionCount; ++i) {
            const option = mkNode('option', { parent: this.move, attrib: { value: i.toString() } });
            option.innerHTML = (i + 1).toString();
        }
        parent.appendChild(this.row);
    }

    public destroy(): void {
        // do something
    }

    public getIndex(): number {
        return this.optionIndex;
    }

    public disable(x: boolean): void {
        this.move.disabled = x;
        if (x) {
            this.option.setAttribute('aria-disabled', 'true');
        } else {
            this.option.setAttribute('aria-disabled', 'false');
        }
    }

    public setValid(valid: boolean): void {
        if (valid) {
            /*this.move.className = 'cpq-button';*/
            this.move.setAttribute('aria-invalid', 'false');
            this.option.setAttribute('aria-invalid', 'false');
        } else {
            /*this.move.className = 'cpq-button-warning';*/
            this.move.setAttribute('aria-invalid', 'true');
            this.option.setAttribute('aria-invalid', 'true');
        }
    }
}


/** CPQ question ordering UI */
class QuestionCPQ extends QuestionBase implements Question {
    private readonly updateVisibility: () => void;
    private readonly answerItem: HTMLDivElement;
    //private readonly answerLabel: HTMLDivElement;
    private readonly answerOptions: HTMLDivElement;
    private readonly reset: HTMLInputElement;
    private readonly order: string[];

    private groupsByValue: { [ix: string]: FieldOrder[] };
    private value: string;

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        options: string[],
    }) {
        super({...args, showFlag: true});
        const {updateVisibility, visibilityExpression, indent, options} = args;
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`; 
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column });
        //this.answerLabel = mkNode('div', { className: 'answer-label', parent: this.answerItem });
        this.answerOptions = mkNode('div', { parent: this.answerItem });
        this.groupsByValue = { '-1': [] };
        for (let i = 0; i < options.length; ++i) {
            this.groupsByValue[i.toString()] = [];
        }
        for (let i = 0; i < options.length; ++i) {
            const opt = new FieldOrder(this.answerOptions, options[i], options.length, i);
            this.groupsByValue[opt.move.value].push(opt);
        }
        const resetPanel = mkNode('div', { className: 'cpq-reset-panel', parent: this.answerItem })
        this.reset = mkNode('input', {
            className: 'cpq-danger-button ' + configSolidDngrButton, parent: resetPanel, attrib: {
                type: 'button', value: 'reset', disabled: 'true'
            }
        });
        this.order = options;
        //frag.appendChild(this.label);
        this.value = 'null';
        //frag.appendChild(this.label);
    }

    public updateDisable(): void {
        super.updateDisable();
        const disabled = this.isDisabled();
        for (const val in this.groupsByValue) {
            for (const opt of this.groupsByValue[val]) {
                opt.disable(disabled);
            }
        }
        this.reset.disabled = disabled;
    }

    private update(): void {
        let optNode = this.answerOptions.firstChild;
        if (!optNode) {
            return;
        }
        for (const val in this.groupsByValue) {
            for (const opt of this.groupsByValue[val]) {
                opt.move.value = val;
                if (opt.row !== optNode) {
                    this.answerOptions.insertBefore(opt.row, optNode);
                } else if (optNode) {
                    optNode = optNode.nextSibling;
                } else {
                    return;
                }
            }
        }
    }

    private reorder(order: string[], reset = false): void {
        const gbv: { [x: string]: FieldOrder[] } = {};

        gbv['-1'] = [];
        for (let i = 0; i < this.order.length; ++i) {
            gbv[i.toString()] = [];
        }

        for (const val in this.groupsByValue) {
            for (const opt of this.groupsByValue[val]) {
                const ix = order.indexOf(this.order[opt.getIndex()]);
                if (!reset && ix > -1) {
                    gbv[ix.toString()].push(opt);
                } else {
                    gbv['-1'].push(opt);
                }
            }
        }

        this.groupsByValue = gbv;
        this.update();
    }

    private validate(): boolean {
        let valid = this.groupsByValue['-1'].length == 0 || this.groupsByValue['-1'].length == this.order.length;
        for (const opt of this.groupsByValue['-1']) {
            opt.setValid(valid);
        }

        if (this.groupsByValue['-1'].length == this.order.length) {
            this.context.setValid(valid);
            return valid;
        }

        for (let v = 0; v < this.order.length; ++v) {
            const grp = this.groupsByValue[v.toString()];
            const validGroup = grp.length == 1;
            for (const opt of grp) {
                opt.setValid(validGroup);
            }
            valid = valid && validGroup;
        }

        this.context.setValid(valid);
        return valid;
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            if (response && isArrayOfString(response.answer)) {
                const order = response.answer;
                if (order != null && order.length == this.order.length) {
                    this.reorder(order);
                    this.value = this.getValue();
                } else if (order != null) {
                    console.log('invalid load: ' + order);
                }
            }
            this.updateVisibility();
        } catch (e) {
            console.error(String(e));
        }
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.answerItem.addEventListener('change', this.handleReorder);
        this.reset.addEventListener('click', this.handleReset);
    }

    private getJson(): Json {
        const order = [];
        for (let i = 0; i < this.order.length; ++i) {
            const grp = this.groupsByValue[i.toString()];
            for (const opt of grp) {
                order.push(this.order[opt.getIndex()]);
            }
        }
        return (order.length < this.order.length) ? null : order;
    }

    /** Get the answer value */
    public getValue(): string {
        return this.value;
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by CPQ */
    public destroy(): void {
        removeNode(this.answerItem);
        this.reset.removeEventListener('click', this.handleReset);
        this.answerItem.removeEventListener('change', this.handleReorder);
        for (const val in this.groupsByValue) {
            for (const opt of this.groupsByValue[val]) {
                opt.destroy();
            }
        }
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    public getAnswer(): AnswerKey & AnswerValue {
        return {qno: this.qno, ano: this.ano, answer: this.value};
    }

    private async save(): Promise<boolean> {
        const valid = this.validate();
        if (valid) {
            const value = this.getJson();
            const valstr = JSON.stringify(value);
            if (valstr != this.value) {
                this.value = valstr;
                try {
                    await this.context.saveAnswer({qno: this.qno, ano: this.ano}, {answer: value});
                } catch (e) {
                    console.error(String(e));
                } finally {
                    return true;
                }
            }
        }
        return false;
    }

    private handleReorder = async (e: Event): Promise<void> => {
        if (!(e.target instanceof HTMLSelectElement)) {
            return;
        }

        moveOption: {
            for (const val in this.groupsByValue) {
                const grp = this.groupsByValue[val];
                for (let i = 0; i < grp.length; ++i) {
                    const opt = grp[i];
                    if (opt.move === e.target) {
                        if (val != opt.move.value) {
                            this.groupsByValue[val].splice(i, 1);
                            this.groupsByValue[opt.move.value].push(opt);
                        }

                        break moveOption;
                    }
                }
            }
            return;
        }

        if (this.groupsByValue['-1'].length == 1) {
            let singletons = 0;
            let empty = null;
            for (let i = 0; i < this.order.length; ++i) {
                const grp = this.groupsByValue[i];
                if (grp.length == 1) {
                    ++singletons;
                } else if (grp.length == 0) {
                    empty = grp;
                }
            }
            if (empty && singletons == this.order.length - 1) {
                const x = this.groupsByValue['-1'].pop();
                if (x)  {
                    empty.push(x);
                }
            }
        }

        this.update();

        if (await this.save()) {
            this.updateVisibility();
        }
    }

    private handleReset = async (): Promise<void> => {
        this.reorder(this.order, true);
        if (await this.save()) {
            this.updateVisibility();
        }
    }
}

registerAnswerType({
    name: 'CPQ',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'cpq';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerPrioritisation,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
    ): Question => {
        return new QuestionCPQ({
            updateVisibility,
            context,
            qno,
            ano,
            backendQid: question.manifest.backend_id,
            backendAid: answer.backend_id,
            showNumber: question.manifest.answers.length > 1,
            label: answer.label,
            frag,
            options: answer.options,
            lightbox,
            isRemoteShowHide,
            indent: answer.indent,
            visibilityExpression: answer.visible,
            notes: answer.notes,
            resources: question.answersResources[ano],
            mandatory: answer.mandatory,
            type: answer.type.toLowerCase(),
        });
    }
});
