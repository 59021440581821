import { mkNode, removeNode } from "@p4b/utils";
import { Img } from '@p4b/image-base';
import { Json, LocalData } from '@p4b/exam-service';
import { ThumbnailViewer } from "@p4b/thumbnail-viewer";
import { Lightbox } from "@p4b/lightbox";
import { translate } from "@p4b/utils-lang";
import { MeetingViewer } from "./meeting";

export interface AnswerFactory {
    name: string;
    isThis(answer: PractiqueNet.ExamJson.Definitions.Answer, n?: number): boolean;
    makeAnswer(
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.Answer,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
    ): Question;
}

export const answerTypes: AnswerFactory[] = [];

export function registerAnswerType(af: AnswerFactory): void {
    console.info('REGISTER_QUESTION', af.name)
    answerTypes.push(af);
}

/** Expression dependent on answer values */
export type ExprVal = PractiqueNet.ExamJson.Definitions.ExprVal;
export type ExprRef = PractiqueNet.ExamJson.Definitions.ExprRef;
export type ExprObject = PractiqueNet.ExamJson.Definitions.ExprObject;
export type ExprEq = PractiqueNet.ExamJson.Definitions.ExprEq;
export type ExprGt = PractiqueNet.ExamJson.Definitions.ExprGt;
export type ExprLt = PractiqueNet.ExamJson.Definitions.ExprLt;
export type ExprGe = PractiqueNet.ExamJson.Definitions.ExprGe;
export type ExprLe = PractiqueNet.ExamJson.Definitions.ExprLe;
export type ExprAnd = PractiqueNet.ExamJson.Definitions.ExprAnd;
export type ExprOr = PractiqueNet.ExamJson.Definitions.ExprOr;
export type ExprXor = PractiqueNet.ExamJson.Definitions.ExprXor;
export type ExprNot = PractiqueNet.ExamJson.Definitions.ExprNot;
export type Expr = PractiqueNet.ExamJson.Definitions.Expr;


/** Identify question independent of local question order in exam */
export interface BackendId {
    backendQid: number;
    backendAid: number;
}

/** Data to save in local DB and send to server */
export interface SaveArgs {
    qno: number;
    ano: number;
    backendQid: number;
    backendAid: number;
    time: number;
    answer: string;
}

export interface ControlPanel {
    add(control: HTMLElement): boolean;
    remove(control: HTMLElement): boolean;
    panel(): HTMLDivElement;
}

export interface NotificationArea {
    show(html: string): void;
    hide(): void;
    setReadOnly(isReadOnly: boolean): void;
    setItem(item?: number): Promise<void>,
    getItem(): number,
}

export interface AnswerKey {
    qno: number,
    ano: number,
}

export interface AnswerValue {
    answer?: Json;
    extra?: {[index:string]: Json};
    timeOnQuestion?: number;
    nextQuestion?: number;
}

export interface Timers {
    set(timer: string, time: number): void;
    get(timer?: string): number;
    start(timer?: string): boolean;
    stop(timer?: string): boolean;
}

/** Injects dependencies for questions */
export interface QuestionContext {
    controlPanel: ControlPanel;
    getNavigating(): boolean;
    setNavigating(x?: boolean): void;
    setFlag(qid: number, aid: number, flag: boolean): Promise<void>;
    getFlag(qid: number, aid: number): Promise<boolean>;
    loadAnswer(qno: number, ano: number): Promise<LocalData|undefined>;
    saveAnswer(key: {qno: number, ano: number}, value: AnswerValue): Promise<void>;
    setVisible(qid: number, aid: number, vis: boolean): void;
    getValid(): boolean;
    setValid(valid: boolean): void;
    setInvalid(iqno: number, iano: number): void;
    getImageBegin(): Promise<void>;
    getImageFrame(start: number, end: number): Promise<ArrayBuffer|undefined>;
    getImageEnd(): Promise<void>;
    getDisplayId(qno: number, ano: number): string|undefined;
    parent: HTMLElement;
    fullscreenParent: HTMLElement;
    //noMouse: boolean;
    //showQuestionTitle: boolean;
    factorDetails?: {[ix: string]: PractiqueNet.ExamJson.Definitions.UserDetails};
    candidateId: string;
    //examId: string;
    meetingBar: HTMLElement;
    //enableCopyPaste: boolean;
    //disableResourceLocking: boolean;
    meta: PractiqueNet.ExamJson.Definitions.ExamMeta;
    notificationArea: HTMLDivElement;
    getTimers(): Timers;
    component?: number;
    meeting: MeetingViewer;
}

export interface AnswerResources {
    thumbnails: (ArrayBuffer|null)[];
    resources: Img[];
}

export interface QuestionManifest {
    manifest: PractiqueNet.ExamJson.Definitions.Question;
    images: Img[];
    thumbnails: (ArrayBuffer|null)[];
    answersResources: AnswerResources[];
}

export class QuestionManifest implements QuestionManifest {
    public constructor(manifest: PractiqueNet.ExamJson.Definitions.Question) {
        this.manifest = manifest;
        this.images = [];
        this.thumbnails = [];
    }
}

export interface Question {
    qno: Readonly<number>;
    ano: Readonly<number>;
    loadFlag(): Promise<void>;
    loadAnswer(answer?: LocalData): void;
    loadResources(thumbnails: (ArrayBuffer|null)[]): Promise<void>
    loadingComplete(): void;
    getValue(): string;
    getAnswer(): AnswerKey & AnswerValue;
    setVisible(x: boolean): void;
    disableResources(x: boolean): void;
    setReadOnly(x: boolean): void;
    destroy(): void;
    focus(): void;
    updateValidity(valid: boolean): void;
    //getResourceStatus(status: {id: string, released: boolean}[]): void;
    //setResourceStatus(status: {id: string, released: boolean}): void;
    visibilityExpression?: Expr;
    thumbnails?: Readonly<ThumbnailViewer>;
    mandatory: Readonly<boolean>;
    displayId?: Readonly<string>;
}

export enum Layout {
    Default,
    Compact,
    CompactFloat,
}

export type QuestionArgs = {
    context: QuestionContext,
    frag: Node,
    qno: number,
    ano: number,
    backendQid: number,
    backendAid: number,
    showNumber: boolean,
    showFlag: boolean,
    label: string,
    lightbox: Lightbox,
    isRemoteShowHide: boolean,
    notes?: string,
    resources?: AnswerResources,
    indent?: number,
    layout?: Layout,
    mandatory?: boolean,
    type: string,
};

/** Base class for questions, renders stem and flag UI */
export class QuestionBase {
    protected readonly context: QuestionContext;
    protected readonly startTime: number;

    protected readonly label: HTMLDivElement;
    protected readonly labelId: HTMLDivElement;
    protected readonly column: HTMLDivElement;
    protected readonly labelPanel: HTMLDivElement;
    protected readonly id: Text;
    //protected readonly text: HTMLSpanElement;
    //protected readonly notes?: HTMLDivElement;
    protected readonly flag: HTMLButtonElement;
    //protected readonly thumbs?: HTMLDivElement;
    //protected readonly ftxt: Text;
    public readonly thumbnails?: ThumbnailViewer;

    protected isLoading = false;
    protected isReadOnly = false;

    public readonly qno: number;
    public readonly ano: number;
    public readonly backendQid: number;
    public readonly backendAid: number;
    public readonly mandatory: boolean;
    public readonly type: string;
    public readonly displayId?: string;
    /** Constructs the QuestionBase UI */
    public constructor({
        context,
        frag,
        qno,
        ano,
        backendQid,
        backendAid,
        showNumber,
        showFlag,
        label,
        lightbox,
        isRemoteShowHide,
        notes,
        resources,
        indent,
        layout = Layout.Default,
        mandatory,
        type,
    }: QuestionArgs) {
        this.context = context;
        this.qno = qno;
        this.ano = ano;
        this.backendQid = backendQid;
        this.backendAid = backendAid;
        this.startTime = Date.now();
        this.mandatory = mandatory === true;
        this.type = type;

        const displayId = context.getDisplayId(this.qno, this.ano);
        this.displayId = displayId;

        this.label = mkNode('div', { className: `answer-label-row config-dngr-fg-invalid ${(this.ano % 2) ? 'config-background-highlight-faint' : 'config-background'}`, attrib: {
            'aria-required': String(mandatory === true),
            'aria-invalid': String(false),
            'data-did': String(this.displayId),
            'data-qid': String(this.backendQid),
            'data-aid': String(this.backendAid),
        }});
        this.labelId = mkNode('div', { className: 'answer-label-id', parent: this.label });

        if (resources && ((resources.thumbnails?.length ?? 0) > 0)) {
            layout = Layout.Default;
        }

        if (layout === Layout.Compact) {
            this.labelPanel = this.label;
            this.column = this.label;
            this.flag = mkNode('button', {
                parent: (showFlag ? this.label : undefined), title: translate('ANSWER_FLAG_TIP'), tabindex: 0, disabled: true,
                attrib: {'aria-pressed': 'false'},
                children: [
                    mkNode('text', { text: '\u2691' })
                ]
            });
            this.flag.style.order = '999';
            this.flag.style.marginRight = '16px';
        } else if (layout === Layout.CompactFloat) {
            this.labelPanel = mkNode('div', {className: 'answer-label-panel answer-label-panel-compact', parent: this.label});
            this.flag = mkNode('button', {
                parent: (showFlag ? this.labelPanel : undefined), title: translate('ANSWER_FLAG_TIP'), tabindex: 0, disabled: true,
                attrib: {'aria-pressed': 'false'},
                children: [
                    mkNode('text', { text: '\u2691' })
                ]
            });
            this.column = mkNode('div', { className: 'answer-label-col-compact', parent: this.labelPanel });
       } else {
            this.column = mkNode('div', { className: 'answer-label-col', parent: this.label });
            this.labelPanel =  mkNode('div', {className: 'answer-label-panel', parent: this.column});
            this.flag = mkNode('button', {
                className:'flag', parent: (showFlag ? this.labelPanel : undefined), title: translate('ANSWER_FLAG_TIP'), tabindex: 0, disabled: true,
                attrib: {'aria-pressed': 'false'},
                children: [
                    mkNode('text', { text: '\u2691' })
                ]
            });
        }

        this.id = mkNode('text', { parent: this.labelId });

        const indentRem = (1.6 * (indent ?? 0) + 1.6).toString();
        this.label.style.paddingLeft = `${indentRem}rem`;

        const text = mkNode('span', { className: 'answer-label-text', parent: this.labelPanel });
        text.innerHTML = (label ?? '') + ((type !== 'label' && !mandatory)? `<i> - ${translate('ANSWER_OPTIONAL')}</i>` : '');

        if (notes) {
            const notesElement = mkNode('div', { className: 'answer-label-notes', parent: this.labelPanel });
            notesElement.innerHTML = notes;
        }

        if (resources && ((resources.thumbnails?.length ?? 0) > 0)) {
            this.thumbnails = new ThumbnailViewer({
                fullscreenParent: this.context.fullscreenParent,
                scrollContainer: this.context.parent,
                sizeReference: this.context.parent,
                getImageBegin: this.context.getImageBegin,
                getImageFrame: this.context.getImageFrame,
                getImageEnd: this.context.getImageEnd,
                getNavigating: this.context.getNavigating,
                setNavigating: this.context.setNavigating,
                isRemoteShowHide,
            }, this.column, resources.resources, lightbox);
        }

        if (showNumber && displayId) {
            this.id.textContent = displayId;
        } else {
            this.labelId.style.display = 'none';
        }

        frag.appendChild(this.label);
        this.isLoading = true;
    }

    public updateValidity(valid: boolean): void {
        this.label.setAttribute('aria-invalid', String(!valid));
        //this.label.className = `answer-label-row
        //    ${(this.ano % 2) ? 'config-background-highlight-faint' : 'config-background'}
        //    ${(valid && this.type !== 'label') ? 'config-safe-text' : ''}`;
    }

    //public getResourceStatus(status: {id: string, released: boolean}[]): void {
    //    this.thumbnails?.getStatus(status);
    //}

    //public setResourceStatus(status: {id: string, released: boolean}) {
    //    this.thumbnails?.setStatus(status);
    //}

    public updateDisable(): void {
        this.flag.disabled = this.isDisabled();
    }

    public async loadResources(thumbnails: (ArrayBuffer|null)[]): Promise<void> {
        if (this.thumbnails) {
            await this.thumbnails.loadResources(thumbnails);
        }
    }

    /** Loads the QuestionBase state */
    public async loadFlag(): Promise<void> {
        try {
            this.setFlag(await this.context.getFlag(this.qno, this.ano));
        } catch (e) {
            console.error(e);
        } finally {
            this.flag.addEventListener('click', this.flagClickHandler);
        }
    }

    public loadingComplete(): void {
        this.isLoading = false;
        this.updateDisable();
    }

    public disableResources(disable: boolean): void {
        this.thumbnails?.disabled(disable);
    }

    /** Frees resources used by QuestionBase */
    public destroy(): void {
        removeNode(this.label);
        if (this.thumbnails) {
            this.thumbnails.destroy();
        }
        this.flag.removeEventListener('click', this.flagClickHandler);
    }

    public setReadOnly(isReadOnly: boolean): void {
        this.isReadOnly = isReadOnly;
        this.updateDisable();
    }

    public isDisabled(): boolean {
        return this.isLoading || this.isReadOnly;
    }

    private setFlag(x: boolean): void {
        if (x) {
            this.flag.setAttribute('aria-pressed', 'true');
            this.flag.className = 'flag config-primary config-primary-hover config-primary-border config-body-border-focus config-primary-shadow-empty-focus';
        } else {
            this.flag.setAttribute('aria-pressed', 'false');
            this.flag.className = 'flag config-background-text config-background-hover config-primary-border-empty config-primary-shadow-empty-focus';
        }
    }

    private readonly flagClickHandler = async (): Promise<void> => {
        if (this.context.getNavigating()) {
            return;
        }

        this.context.setNavigating(true);
        try {
            this.flag.disabled = true;
            const notFlag = this.flag.getAttribute('aria-pressed') !== 'true';
            await this.context.setFlag(this.qno, this.ano, notFlag);
            this.setFlag(notFlag);
        } catch (e) {
            console.error(e);
        } finally {
            this.flag.disabled = false;
            this.context.setNavigating(false);
            this.flag.focus();
        }
    }
}
