import {faExpand, faCompress, faTimes, faArrowLeft, faArrowRight, faUndo} from '@fortawesome/free-solid-svg-icons';
import {
    Transform, estimateTranslationScaling,
    estimateTranslationScalingRotation, detectBrowser,
    removeNode, makeElements, wait, NodeSpecObject, replaceIcon,
    /*getReqFullscreen, getExitFullscreen,*/ removeChildren, mkNode, scrollRangeIntoView, roundTo
} from '@p4b/utils';
import { Renderer } from '@p4b/image-base';
import { isDicomRenderer } from '@p4b/Renderers/render-dicom';
import ResizeObserver from 'resize-observer-polyfill';
import { PdfRenderer } from './Renderers/render-pdf';
import { translate } from './utils-lang';

export { makeRenderer } from '@p4b/image-base';

//----------------------------------------------------------------------------
// JSON DOM Component

const measureColour = 'red';
const overlayColour = '#0f72c3';

function xorder({x: x0, y: y0}: {x: number, y: number}, {x: x1,y: y1}: {x: number, y: number}): [{x: number, y: number}, {x: number, y: number}] {
    if (x0 <= x1) {
        return [{x: x0, y: y0}, {x: x1, y: y1}];
    } else {
        return [{x: x1, y: y1}, {x: x0, y: y0}];
    }
}

function unitName(units: string): {units: string, scale: (x: number) => number} {
    switch (units.toUpperCase()) {
        case 'OD':
            return {units: 'OD', scale: x => x / 1000};
        case 'HU':
            return {units: 'HU', scale: x => x};
        case 'US':
            return {units: '', scale: x => x};
        case 'MGML':
            return {units: 'mg/ml', scale: x => x};
        case 'Z_EFF':
            return {units: 'Eff-Z', scale: x => x};
        case 'ED':
            return {units: 'e/ml', scale: x => x * 1023};
        case 'EDW':
            return {units: 'EDNW', scale: x => x};
        case 'HU_MOD':
            return {units: 'HU(mod)', scale: x => x};
        case 'PCT':
            return {units: '%', scale: x => x};
        default:
            if (units.match(/Houndsfield Unit/ig)) {
                return {units: 'HU', scale: x => x};
            }
            return {units, scale: x => x};
    }
}

interface Measure {
    draw(context: CanvasRenderingContext2D, t: Transform, pixelScale: number, renderer?: Renderer): void;
    select({x, y, t, pixelScale}: {x: number, y: number, t: Transform, pixelScale: number}): boolean;
    origin({x, y}: {x: number, y: number}): void;
    point({x, y}: {x: number, y: number}): void;
    size(): number;
}

type MeasureArgs = {sx?: number, sy?: number, rh?: number};

class Linear implements Measure {
    private x0 = 0;
    private y0 = 0;
    private x1 = 0;
    private y1 = 0;

    private rh: number;
    private sx2?: number;
    private sy2?: number;

    constructor({sx, sy, rh = 8}: MeasureArgs) {
        this.rh = rh;
        this.sx2 = sx && sx * sx;
        this.sy2 = sy && sy * sy;
    }

    public origin({x, y}: {x: number, y: number}): void {
        this.x0 = x;
        this.y0 = y;
    }

    public point({x, y}: {x: number, y: number}): void {
        this.x1 = x;
        this.y1 = y;
    }

    public size() {
        const dx = this.x1 - this.x0;
        const dy = this.y1 - this.y0;
        return Math.sqrt(dx * dx + dy * dy);
    }

    public select({x, y, t, pixelScale}: {x: number, y: number, t: Transform, pixelScale: number}): boolean {
        const {x: x1, y: y1} = t.apply({x: this.x1, y: this.y1})
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < this.rh * pixelScale) {
            return true;
        }
        const {x: x0, y: y0} = t.apply({x: this.x0, y: this.y0})
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < this.rh * pixelScale) {
            [this.x0, this.x1] = [this.x1, this.x0];
            [this.y0, this.y1] = [this.y1, this.y0];
            return true;
        }
        return false;
    }

    public draw(context: CanvasRenderingContext2D, t: Transform, pixelScale: number): void {
        const [{x: x0, y: y0}, {x: x1, y: y1}] = xorder(t.apply({x: this.x0, y: this.y0}), t.apply({x: this.x1, y: this.y1}))
        , r = this.rh * pixelScale
        , w = 2 * pixelScale
        , dx = x1 - x0
        , dy = y1 - y0
        , length = Math.sqrt(dx*dx + dy*dy)
        , a = Math.atan2(dy, dx)
        ;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineWidth = pixelScale;
        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${16 * pixelScale}px "Noto Sans"`;
        context.beginPath();
        if (length > 2 * (r + w)) {
            const b = Math.atan2(w / 2, r + w);
            context.moveTo(x0 + r, y0);
            context.ellipse(x0, y0, r, r, 0, 0, 2*Math.PI);
            context.moveTo(x1 + r, y1);
            context.ellipse(x1, y1, r, r, 0, 0, 2*Math.PI);
            context.moveTo(x0 + (r + w) * Math.cos(a + b), y0 + (r + w) * Math.sin(a + b));
            context.ellipse(x0, y0, r + w, r + w, 0, a + b, a + 2*Math.PI - b);
            context.ellipse(x1, y1, r + w, r + w, 0, a + b + Math.PI, a + 3*Math.PI - b);
            context.lineTo(x0 + (r + w) * Math.cos(a + b), y0 + (r + w) * Math.sin(a + b));
        } else {
            context.moveTo(x0 + r * Math.cos(a + Math.PI/2), y0 + r * Math.sin(a + Math.PI/2));
            context.ellipse(x0, y0, r, r, 0, a + 0.5*Math.PI, a + 1.5*Math.PI);
            context.ellipse(x1, y1, r, r, 0, a + 1.5*Math.PI, a + 0.5*Math.PI);
            context.lineTo(x0 + r * Math.cos(a + Math.PI/2), y0 + r * Math.sin(a + Math.PI/2));
            context.moveTo(x0 + (r + w) * Math.cos(a + Math.PI/2), y0 + (r + w) * Math.sin(a + Math.PI/2));
            context.ellipse(x0, y0, r + w, r + w, 0, a + 0.5*Math.PI, a + 1.5*Math.PI);
            context.ellipse(x1, y1, r + w, r + w, 0, a + 1.5*Math.PI, a + 0.5*Math.PI);
            context.lineTo(x0 + (r + w) * Math.cos(a + Math.PI/2), y0 + (r + w) * Math.sin(a + Math.PI/2));
        }
        context.stroke();
        context.fill('evenodd');
        let tx = 1, ty = 1, unit = 'px';
        if (this.sx2 !== undefined && this.sy2 !== undefined) {
            tx *= this.sx2;
            ty *= this.sy2;
            unit = 'mm';
        }
        const du = this.x1 - this.x0
        , dv = this.y1 - this.y0
        , measure = Math.round(Math.sqrt(du * du * tx + dv * dv * ty) * 100) / 100
        , text = `${measure}${unit}`
        ;
        context.textBaseline = 'middle';
        context.strokeText(text, x1 + r + 3 * w, y1);
        context.fillText(text, x1 + r + 3 * w, y1);
    }
}


function handle({context, x, y, width, size}: {context: CanvasRenderingContext2D, x: number, y: number, width: number, size: number}) {
    const w = width / 2;
    context.beginPath();
    context.moveTo(x + w, y + w);
    context.lineTo(x + w + size, y + w);
    context.lineTo(x + w + size, y - w);
    context.lineTo(x + w, y - w);
    context.lineTo(x + w, y - w - size)
    context.lineTo(x - w, y - w - size);
    context.lineTo(x - w, y - w);
    context.lineTo(x - w - size, y - w);
    context.lineTo(x - w - size, y + w);
    context.lineTo(x - w, y + w);
    context.lineTo(x - w, y + w + size);
    context.lineTo(x + w, y + w + size);
    context.lineTo(x + w, y + w);
    context.stroke();
    context.fill('evenodd');
}


class Ellipse implements Measure {
    private x0 = 0;
    private y0 = 0;
    private x1 = 0;
    private y1 = 0;

    private rh: number;
    private sx?: number;
    private sy?: number;

    constructor({sx, sy, rh = 8}: MeasureArgs) {
        this.rh = rh;
        this.sx = sx
        this.sy = sy
    }

    public origin({x, y}: {x: number, y: number}): void {
        this.x0 = x;
        this.y0 = y;
    }

    public point({x, y}: {x: number, y: number}): void {
        this.x1 = x;
        this.y1 = y;
    }

    public size() {
        const dx = this.x1 - this.x0;
        const dy = this.y1 - this.y0;
        return Math.sqrt(dx * dx + dy * dy);
    }

    public select({x, y, t, pixelScale}: {x: number, y: number, t: Transform, pixelScale: number}): boolean {
        const {x: x1, y: y1} = t.apply({x: this.x1, y: this.y1})
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < this.rh * pixelScale) {
            return true;
        }
        const {x: x0, y: y0} = t.apply({x: this.x0, y: this.y0})
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < this.rh * pixelScale) {
            [this.x0, this.x1] = [this.x1, this.x0];
            [this.y0, this.y1] = [this.y1, this.y0];
            return true;
        }
        return false;
    }

    public draw(context: CanvasRenderingContext2D, t: Transform, pixelScale: number, renderer: Renderer): void {
        const [{x: x0, y: y0}, {x: x1, y: y1}] = xorder(t.apply({x: this.x0, y: this.y0}), t.apply({x: this.x1, y: this.y1}))
        , r = this.rh * pixelScale
        , w = 2 * pixelScale
        , lh = 16 * pixelScale
        , dx = x1 - x0
        , dy = y1 - y0
        , mx = Math.abs(this.x1 - this.x0) * 0.5 * t.scaling()
        , my = Math.abs(this.y1 - this.y0) * 0.5 * t.scaling()
        ;
        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineWidth = 1 * pixelScale;
        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${lh}px "Noto Sans"`;

        handle({context, x: x0, y: y0, width: w, size: r});
        if (dy > 0 || dx > 0) {
            handle({context, x: x1, y: y1, width: w, size: r});
        }

        const a = t.rotation();
        const {x: ax, y: ay} = t.apply({x: Math.max(this.x0, this.x1), y: (this.y0 + this.y1) / 2});
        context.beginPath();
        context.moveTo(ax, ay);
        context.ellipse((x0 + x1) / 2, (y0 + y1) / 2, mx, my, a, 0, 2*Math.PI);
        context.ellipse((x0 + x1) / 2, (y0 + y1) / 2, mx + w, my + w, a, 0, 2*Math.PI);
        context.stroke();
        context.fill('evenodd');

        const vx = x1 + r;
        const vy = y1;

        let tx = 1, ty = 1, unit = 'px\u00b2';
        if (this.sx !== undefined && this.sy !== undefined) {
            tx *= this.sx;
            ty *= this.sy;
            unit = 'mm\u00b2';
        }
        const du = Math.abs((this.x1 - this.x0) / 2)
        , dv = Math.abs((this.y1 - this.y0) / 2)
        , measure = Math.round(Math.PI * du * tx * dv * ty * 100) / 100
        , text = `${measure}${unit}`
        ;
        context.textBaseline = 'middle';
        context.strokeText(text, vx + 3 * w, vy);
        context.fillText(text, vx + 3 * w, vy);

        if (isDicomRenderer(renderer)) {
            const mx = (this.x0 + this.x1) / 2.0
            , my =(this.y1 + this.y0) / 2.0
            , a = (this.x1 - this.x0) / 2.0
            , b = (this.y1 - this.y0) / 2.0
            , a2 = a * a
            , ab = a2 / (b * b)
            , {mean, stddev} = renderer.convexMean({y0: this.y0, y1: this.y1, f: y => {
                const dx = Math.sqrt(a2 - ab * (y - my) ** 2);
                return {x0: mx - dx , x1: mx + dx};
              }})
            , {units, scale} = unitName(renderer.img.rescaleType ?? ((renderer.img.modality.toUpperCase() === 'CT') ? 'HU' : ''))
            ;
            let dy = 1.5 * lh;
            if (mean !== undefined && mean === mean) {
                const mtxt = `\u03bc = ${roundTo(scale(mean), 2)}${units ? ` ${units}` : ''}`;
                context.strokeText(mtxt, vx + 3 * w, vy - dy);
                context.fillText(mtxt, vx + 3 * w, vy - dy);
                dy += 1.5 * lh;
            }
            if (stddev !== undefined && stddev === stddev) {
                const stxt = `\u03c3 = ${roundTo(scale(stddev), 2)}${units ? ` ${units}` : ''}`;
                context.strokeText(stxt, vx + 3 * w, vy - dy);
                context.fillText(stxt, vx + 3 * w, vy - dy);
            }
        }
    }
}


class Rectangle implements Measure {
    private x0 = 0;
    private y0 = 0;
    private x1 = 0;
    private y1 = 0;

    private rh: number;
    private sx?: number;
    private sy?: number;

    constructor({sx, sy, rh = 8}: MeasureArgs) {
        this.rh = rh;
        this.sx = sx
        this.sy = sy
    }

    public origin({x, y}: {x: number, y: number}): void {
        this.x0 = x;
        this.y0 = y;
    }

    public point({x, y}: {x: number, y: number}): void {
        this.x1 = x;
        this.y1 = y;
    }

    public size() {
        const dx = this.x1 - this.x0;
        const dy = this.y1 - this.y0;
        return Math.abs(dx * dy);
    }

    public select({x, y, t, pixelScale}: {x: number, y: number, t: Transform, pixelScale: number}): boolean {
        const {x: x1, y: y1} = t.apply({x: this.x1, y: this.y1})
        , dx = x - x1
        , dy = y - y1
        ;
        if (Math.sqrt(dx * dx + dy * dy) < this.rh * pixelScale) {
            return true;
        }
        const {x: x0, y: y0} = t.apply({x: this.x0, y: this.y0})
        , du = x - x0
        , dv = y - y0
        ;
        if (Math.sqrt(du * du + dv * dv) < this.rh * pixelScale) {
            [this.x0, this.x1] = [this.x1, this.x0];
            [this.y0, this.y1] = [this.y1, this.y0];
            return true;
        }
        return false;
    }

    public draw(context: CanvasRenderingContext2D, t: Transform, pixelScale: number, renderer: Renderer): void {
        let x0 = this.x0
        , x1 = this.x1
        , y0 = this.y0
        , y1 = this.y1
        ;

        if (x1 < x0) {
            [x0, x1] = [x1, x0];
            [y0, y1] = [y1, y0];
        }

        const xr = this.rh * pixelScale / t.scaling()
        , yr = (y1 < y0) ? -xr : xr
        , w = 2 * pixelScale
        , lh = 16 * pixelScale
        , ps = [
            t.apply({x: x0, y: y0 - yr}),
            t.apply({x: x0, y: y1}),
            t.apply({x: x1 + xr, y: y1}),
            t.apply({x: x0 - xr, y: y0}),
            t.apply({x: x1, y: y0}),
            t.apply({x: x1, y: y1 + yr}),
        ];

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.lineJoin = 'miter';
        context.miterLimit = 2;
        context.font = `${lh}px "Noto Sans"`;

        context.beginPath();
        context.strokeStyle = 'black';
        context.lineWidth = 3 * pixelScale;
        context.moveTo(ps[0].x, ps[0].y);
        context.lineTo(ps[1].x, ps[1].y);
        context.lineTo(ps[2].x, ps[2].y);
        context.moveTo(ps[3].x, ps[3].y);
        context.lineTo(ps[4].x, ps[4].y);
        context.lineTo(ps[5].x, ps[5].y);
        context.stroke();

        context.beginPath();
        context.strokeStyle = measureColour;
        context.lineWidth = 2 * pixelScale;
        context.moveTo(ps[0].x, ps[0].y);
        context.lineTo(ps[1].x, ps[1].y);
        context.lineTo(ps[2].x, ps[2].y);
        context.moveTo(ps[3].x, ps[3].y);
        context.lineTo(ps[4].x, ps[4].y);
        context.lineTo(ps[5].x, ps[5].y);
        context.stroke();

        let vx = ps[0].x;
        let vy = ps[0].y;
        //let mx = 0;
        for (let i = 1; i < ps.length; ++i) {
            if (ps[i].x > vx) {
                vx = ps[i].x;
                vy = ps[i].y;
                //mx = i;
            }
        }

        context.strokeStyle = 'black';
        context.fillStyle = measureColour;
        context.lineWidth = pixelScale;
        let tx = 1, ty = 1, unit = 'px\u00b2';
        if (this.sx !== undefined && this.sy !== undefined) {
            tx *= this.sx;
            ty *= this.sy;
            unit = 'mm\u00b2';
        }
        const du = Math.abs(this.x1 - this.x0)
        , dv = Math.abs(this.y1 - this.y0)
        , measure = Math.round(du * tx * dv * ty * 100) / 100
        , text = `${measure} ${unit}`
        ;
        context.textBaseline = 'middle';
        context.strokeText(text, vx + 3 * w, vy);
        context.fillText(text, vx + 3 * w, vy);

        if (isDicomRenderer(renderer)) {
            const {mean, stddev} = renderer.convexMean({y0: this.y0, y1: this.y1, f: () => ({x0: this.x0, x1:this.x1})})
            , {units, scale} = unitName(renderer.img.rescaleType ?? ((renderer.img.modality.toUpperCase() === 'CT') ? 'HU' : ''))
            ;
            let dy = 1.5 * lh;
            if (mean !== undefined && mean === mean) {
                const mtxt = `\u03bc = ${scale(roundTo(mean, 2))}${units ? ` ${units}` : ''}`;
                context.strokeText(mtxt, vx + 3 * w, vy - dy);
                context.fillText(mtxt, vx + 3 * w, vy - dy);
                dy += 1.5 * lh;
            }
            if (stddev !== undefined && stddev === stddev) {
                const stxt = `\u03c3 = ${scale(roundTo(stddev, 2))}${units ? ` ${units}` : ''}`;
                context.strokeText(stxt, vx + 3 * w, vy - dy);
                context.fillText(stxt, vx + 3 * w, vy - dy);
            }
        }
    }
}


interface DicomViewerUi {
    viewer: HTMLDivElement;
    title: HTMLDivElement;
    canvasPanel: HTMLDivElement;
    canvas: HTMLCanvasElement;
    progress: HTMLSpanElement;
    //controlBar: HTMLDivElement;
    control: HTMLSelectElement;
    reset: HTMLInputElement;
    prev: HTMLButtonElement;
    next: HTMLButtonElement;
    expand: HTMLButtonElement;
    expandIcon: HTMLSpanElement;
    //expandText: HTMLSpanElement;
    close: HTMLButtonElement;
    tpar: HTMLDivElement;
    tspan: HTMLDivElement;
}

function getDicomViewerUi(): NodeSpecObject<DicomViewerUi> {
    return {
        viewer: { elem: 'div', className: 'viewer-panel config-background', style: {display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: '100%', height: '100%'}},
        title: { elem: 'div', className: 'title-new', parent: 'viewer'},
        canvasPanel: {elem: 'div', className: 'canvas-panel', style: {display: 'none', overflowY: 'auto'}, parent: 'viewer'},
        canvas: { elem: 'canvas', id: 'image-view', className: 'dicom-canvas', parent: 'canvasPanel', attrib: { tabindex: '0', 'moz-opaque': 'true' } },
        progress: { elem: 'span', className: 'canvas-progress', parent: 'canvasPanel' },
        //title: { elem: 'div', className: 'control-bar config-body-fg-light-background config-body-fg-light-background-text', style: {zIndex: '9999'}},
        tpar: {elem: 'div', className: 'slim-control config-body-fg-light-background config-body-fg-light-background-text', parent: 'title', style: {
            flex: '1',
            padding: '4px 4px 4px 9px',
            marginLeft: '0',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }},
        tspan: {elem: 'div', parent: 'tpar', style: {
            padding: '0',
            margin: '0',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }},
        control: {
            elem: 'select', tip: translate('VIEWER_SELECT'), className: 'slim-dropdown config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title'
        },
        reset: {elem: 'button', tip: translate('VIEWER_RESET'), className: 'slim-control config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title', attrib: {hidden: 'true'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faUndo},
        ]},
        prev: { elem: 'button', tip: translate('VIEWER_PREV'), className: 'slim-control config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title', attrib: {hidden: 'true'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faArrowLeft},
            //{elem: 'span', className: 'control-text', children: [
            //    {elem: 'text', text: 'PREV'},
            //]},
        ]},
        next: { elem: 'button', tip: translate('VIEWER_NEXT'), className: 'slim-control config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title', attrib: {hidden: 'true'}, children: [
            {elem: 'icon', className: 'control-icon', icon: faArrowRight},
            //{elem: 'span', className: 'control-text', children: [
            //    {elem: 'text', text: 'NEXT'},
            //]},
        ]},
        expand: { elem: 'button', tip: translate('VIEWER_FULLSCREEN'), className: 'slim-control config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title'},
        expandIcon: {elem: 'span', parent: 'expand', children: [{elem: 'icon', className: 'control-icon', icon: faExpand}]},
        //expandText: {elem: 'span', parent: 'expand', className: 'control-text', children: [{elem: 'text', text: 'MAX'}]},
        close: { elem: 'button', tip: translate('VIEWER_CLOSE'), className: 'slim-control config-body-fg-light-background config-body-fg-light-background-hover config-body-fg-light-background-text', parent: 'title', children: [
            {elem: 'icon', className: 'control-icon', icon: faTimes},
            //{elem: 'span', className: 'control-text', children: [
            //    {elem: 'text', text: 'CLOSE'},
            //]},
        ]},
    }
}

function findOption(select: HTMLSelectElement, text: string): number | null {
    for (let i = 0; i < select.length; ++i) {
        if (select.options[i].value === text) {

            return i;
        }
    }
    return null;
}

//----------------------------------------------------------------------------
// DICOM Viewer


interface Point {
    clientX: number;
    clientY: number;
}


class Control {
    public scaleX = 1.0;
    public scaleY = 1.0;

    public readonly cssClass: string;
    public onstart?: (c: Control, x: number, y: number) => Promise<void>;
    public onstep?: (x: number, y: number) => Promise<void>;
    public onend?: () => Promise<void>;
    public mode?: string;

    public async start(point: Point, canvas: HTMLElement): Promise<void> {
        if (this.onstart != null) {
            const rect = canvas.getBoundingClientRect();
            await this.onstart(this,
                (point.clientX - rect.left) * this.scaleX,
                (point.clientY - rect.top) * this.scaleY
            );
        }
    }

    public async step(point: Point, canvas: HTMLElement): Promise<void> {
        if (this.onstep != null) {
            const rect = canvas.getBoundingClientRect();
            await this.onstep(
                (point.clientX - rect.left) * this.scaleX,
                (point.clientY - rect.top) * this.scaleY
            );
        }
    }

    public async end(): Promise<void> {
        if (this.onend != null) {
            await this.onend();
        }
    }

    public clear(): void {
        this.onstart = undefined;
        this.onstep = undefined;
        this.onend = undefined;
        this.mode = undefined;
    }

    public constructor(cssClass: string) {
        this.clear();
        this.cssClass = cssClass;
    }
}


class Location {
    public dx: number;
    public dy: number;
    public rx: number;
    public ry: number;

    public constructor(dx: number, dy: number, rx: number, ry: number) {
        this.dx = dx;
        this.dy = dy;
        this.rx = rx;
        this.ry = ry;
    }
}


interface DicomViewerArgs {
    parent: HTMLElement;
    fullscreenParent: Element; // HTMLElement;
    scrollContainer: Element; // HTMLElement
    after: Node|null; // HTMLElement | null;
    sizeReference: Element; // HTMLElement;
    getImageBegin: () => Promise<void>;
    getImageFrame: (start: number, end: number) => Promise<ArrayBuffer|undefined>;
    getImageEnd: () => Promise<void>;
    getNavigating: () => boolean;
    //noMouse: boolean;
    window: Window;
    forceFullscreen?: boolean;
}


export class DicomViewer {
    private args: DicomViewerArgs;
    private ui: DicomViewerUi;
    private renderer?: Renderer;
    private scaleTransform = Transform.identity;
    private left: Control;
    private right: Control;
    //private rem: number;
    //private renderRequested = false;
    private postFrame?: (context: CanvasRenderingContext2D, sx?: number, sy?: number, px?: number, py?: number) => void;
    private selectedControl?: number;
    private forceFullscreen: boolean;
    private fullscreen: boolean;
    private hidden?: string;
    private visibilityChange?: string;
    private scaleX: number;
    private scaleY: number
    private lastFrame: DOMHighResTimeStamp = 0;
    private resizeObserver: ResizeObserver;
    private measures = new Map<number, Measure[]>();

    public onclose?: () => void;

    public getRenderer(): Renderer|undefined {
        return this.renderer;
    }

    public constructor(args: DicomViewerArgs) {
        //console.log('IMAGE_VIEWER CONSTRUCT');
        this.args = args;
        const fs = getComputedStyle(this.args.window.document.documentElement).fontSize;
        if (fs == null) {
            throw ('FONT_SIZE is null');
        }
        //this.rem = parseFloat(fs);
        this.ui = makeElements(getDicomViewerUi());
        this.ui.close.disabled = args.getNavigating();
        const after = this.args.after ? this.args.after.nextSibling : null;
        this.args.parent.insertBefore(this.ui.viewer, after); //this.args.after.nextSibling);
        //this.args.parent.insertBefore(this.ui.canvasPanel, after); //this.args.after.nextSibling);
        //this.args.parent.insertBefore(this.ui.title, this.ui.canvasPanel);
        this.fullscreen = false;
        this.forceFullscreen = false;
        if (args.forceFullscreen) {
            this.toggleFullscreen();
            this.forceFullscreen = true;
        }
        this.left = new Control('control-selected-left');
        this.right = new Control('control-selected-right');
        const passive: AddEventListenerOptions & EventListenerOptions = { passive: true };
        const notPassive: AddEventListenerOptions & EventListenerOptions = { passive: false };
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(this.ui.canvasPanel);
        //this.args.window.addEventListener('resize', this.windowResizeHandler);
        this.args.window.addEventListener('mousemove', this.windowMousemoveHandler);
        this.args.window.addEventListener('touchmove', this.windowTouchmoveHandler, notPassive);
        this.args.window.addEventListener('mouseup', this.windowMouseupHandler);
        this.args.window.addEventListener('touchend', this.windowTouchendHandler, notPassive);
        this.args.window.addEventListener('contextmenu', this.canvasContextmenuHandler);
        this.ui.canvas.addEventListener('keydown', this.canvasKeydownHandler, notPassive);
        this.ui.canvas.addEventListener('click', this.canvasClickHandler);
        this.ui.canvas.addEventListener('dblclick', this.canvasDblclickHandler);
        this.ui.canvas.addEventListener('mousedown', this.canvasMousedownHandler);
        this.ui.canvas.addEventListener('touchstart', this.canvasTouchstartHandler, notPassive);
        //this.ui.canvas.addEventListener('wheel', this.canvasMouseWheelHandler, notPassive);
        this.ui.control.addEventListener('change', this.controlChangeHandler);
        this.ui.close.addEventListener('click', this.closeClickHandler, passive);
        this.ui.prev.addEventListener('click', this.prevClickHandler, passive);
        this.ui.next.addEventListener('click', this.nextClickHandler, passive);
        this.ui.expand.addEventListener('click', this.expandClickHandler, passive);
        this.ui.reset.addEventListener('click', this.resetClickHandler, passive);
        this.args.window.addEventListener('beforeunload', this.unloadHandler);
        if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
            this.hidden = "hidden";
            this.visibilityChange = "visibilitychange";
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if (typeof (document as any).msHidden !== "undefined") {
            this.hidden = "msHidden";
            this.visibilityChange = "msvisibilitychange";
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } else if (typeof (document as any).webkitHidden !== "undefined") {
            this.hidden = "webkitHidden";
            this.visibilityChange = "webkitvisibilitychange";
        }
        if (this.visibilityChange) {
            this.args.window.addEventListener(this.visibilityChange, this.visibilityHandler);
        }
        this.scaleX = 1.0;
        this.scaleY = 1.0;
    }

    private unloadHandler = (): void => {
        this.destroy();
        //console.log('UNLOAD');
    }

    private visibilityHandler = (): void => {
        if (this.hidden) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            //console.log('VISIBILITY_CHANGE:', ((this.args.window.document as any)[this.hidden] as boolean));
        }
        //console.log(' GLOBAL:', window);
        //console.log('  LOCAL:', this.args.window)
    }

    //------------------------------------------------------------------------
    // Drawing Frames

    private async animationFrame(): Promise<void> {
        const context = this.ui.canvas.getContext('2d', { alpha: false });
        if (context) {
            //context.resetTransform(); // not in iOS9
            context.setTransform(1, 0, 0, 1, 0, 0);
            //context.fillStyle='black';
            //context.fillRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
            if (this.renderer && this.scaleTransform) {
                const t = this.transform.multiplyBy(this.scaleTransform);
                //const t = this.transform; // this.scaleTransform;
                //const t = this.scaleTransform.multiplyBy(this.transform);
                //context.setTransform(t.s, t.r, -t.r, t.s, t.tx, t.ty);
                await this.renderer.animationFrame(context, t);
                const index = this.renderer?.index ?? 1;
                const measures = this.measures.get(index);
                if (measures) {
                    for (const measure of measures) {
                        measure.draw(context, t, this.scaleY, this.renderer);
                    }
                }
            } else {
                //context.clearRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
                context.fillStyle='black';
                context.fillRect(0, 0, this.ui.canvas.width, this.ui.canvas.height);
                console.error('CANVAS or TRANSFORM error', this.renderer?.img, this.scaleTransform);
            }
            if (this.postFrame) {
                //context.resetTransform(); // not in iOS9
                if (context) {
                    context.setTransform(1, 0, 0, 1, 0, 0);
                    this.postFrame(context);
                }
            }
        } else {
            console.error('CONTEXT error');
        }
    }

    private rendering = false;

    private async requestRender(): Promise<void> {
        if (!this.rendering) {
            this.rendering = true;
            try {
                if (this.renderer) {
                    await this.renderer.render();
                } else {
                    console.error('RENDERER is not defined');
                }
                this.requestFrame();
            } catch(err) {
                console.error(`RENDER_ERROR: ${String(err)}`);
            } finally {
                this.rendering = false;
            }
        }
    }

    private requestedAnimationFrame: number|undefined;

    private requestFrame(): void {
        if (this.requestedAnimationFrame !== undefined) {
            return;
        }
        this.requestedAnimationFrame = this.args.window.requestAnimationFrame(async (timestamp: DOMHighResTimeStamp) => {
            if (timestamp == this.lastFrame) {
                return;
            }
            this.lastFrame = timestamp;
            try {
                //await yieldMicroTask();
                await this.animationFrame();
            } catch (err) {
                console.error(`FRAME_ERROR: ${String(err)}`);
            } finally {
                this.requestedAnimationFrame = undefined;
            }
        });
    }

    /*
        console.log('REQUEST FRAME');
        //if (this.renderRequested) {
        //    return this.renderRequested;
        //}
        if (this.renderRequested) {
            await this.renderRequested;
        }
        this.renderRequested = new Promise(succ => {
            this.args.window.requestAnimationFrame(async (timestamp: DOMHighResTimeStamp) => {
                if (timestamp == this.lastFrame) {
                    return;
                }
                this.lastFrame = timestamp;
                try {
                    await this.animationFrame();
                } catch (e) {
                    console.error('FRAME_ERROR:', e);
                } finally {
                    this.renderRequested = undefined;
                    succ();
                }
            });
        });
        return this.renderRequested;
    }
    */

    //------------------------------------------------------------------------
    // Image Controls

    public readonly canvasClickHandler = (event: MouseEvent): boolean => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    public readonly canvasContextmenuHandler = (event: MouseEvent): boolean => {
        event.preventDefault();
        event.stopPropagation();
        return false;
    }

    private async toggleFullscreen(exit = false): Promise<void> {
        if (this.forceFullscreen) {
            return;
        }
        if (!this.fullscreen && exit) {
            return;
        }
        this.fullscreen = !this.fullscreen;
        //removeNode(this.ui.canvasPanel);
        if (this.fullscreen && this.args && this.args.fullscreenParent) {
            this.args.fullscreenParent.appendChild(this.ui.viewer);
            //]getReqFullscreen().call(this.ui.canvasPanel);
            this.ui.viewer.className = 'viewer-panel-fullscreen config-background';
        } else if (this.args && this.args.parent) {
            const after = this.args.after ? this.args.after.nextSibling : null;
            this.args.parent.insertBefore(this.ui.viewer, after);
            //this.args.parent.appendChild(this.ui.viewer);
            //getExitFullscreen().call(document);
            this.ui.viewer.className = 'viewer-panel config-background';
        }
        setImmediate(async () => {
            //await this.resize();
            setImmediate((): void => {
                this.resize(true);
                scrollRangeIntoView(this.ui.viewer);
                //this.scrollToImage();
                this.ui.canvas.focus();
            });
        });
    }

    public readonly canvasDblclickHandler = (event: MouseEvent): void => {
        this.toggleFullscreen();
        this.scaleToFit();
        event.preventDefault();
    }

    //------------------------------------------------------------------------
    // Mouse handling

    public readonly canvasMousedownHandler = async (event: MouseEvent): Promise<void> => {
        //this.args.window.focus();
        if (!this.scroll) {
            //this.ui.canvas.focus();
            const button = (event.button === 2) ? this.right : this.left;
            if (button && button.onstart) {
                await button.start({clientX: event.clientX, clientY: event.clientY}, this.ui.canvas);
                //event.preventDefault();
            }
        }
    }


    public readonly windowMousemoveHandler = async (event: MouseEvent): Promise<void> => {
        event.preventDefault();
        if (this.left && this.left.onstep) {
            await this.left.step({clientX: event.clientX, clientY: event.clientY}, this.ui.canvas);
        } else if (this.right && this.right.onstep) {
            await this.right.step({clientX: event.clientX, clientY: event.clientY}, this.ui.canvas);
        }
    }

    public readonly windowMouseupHandler = async (event: MouseEvent): Promise<void> => {
        event.preventDefault();
        if (this.left && this.left.onend) {
            await this.left.end();
        } else if (this.right && this.right.onend) {
            await this.right.end();
        }
    }

    public readonly canvasMouseWheelHandler = async (event: WheelEvent): Promise<void> => {
        event.preventDefault();
        await this.upDown((event.deltaY > 0) ? 1 : (event.deltaY < 0) ? -1 : 0);
    }

    //------------------------------------------------------------------------
    // Touch handling

    private locations: Map<number, Location> = new Map();
    private committedTransform = Transform.identity;
    private transform = Transform.identity;

    private commitTransform(transform: Transform): Transform {
        const domain: [number, number][] = [];
        const range: [number, number][] = [];
        const values = this.locations.values();
        let p = values.next();
        while (!p.done) {
            domain.push([p.value.dx, p.value.dy]);
            range.push([p.value.rx, p.value.ry]);
            p.value.dx = p.value.rx;
            p.value.dy = p.value.ry;
            p = values.next();
        }
        let t;
        if (this.renderer) { // && isDicomRenderer(this.renderer)) {
            t = estimateTranslationScalingRotation(domain, range);
        } else {
            t = estimateTranslationScaling(domain, range);
        }
        return t.multiplyBy(transform);
    }

    private updateTransform(transform: Transform): Transform {
        const domain: [number, number][] = [];
        const range: [number, number][] = [];
        const values = this.locations.values();
        let p = values.next();
        while (!p.done) {
            domain.push([p.value.dx, p.value.dy]);
            range.push([p.value.rx, p.value.ry]);
            p = values.next();
        }

        let t;
        if (this.renderer) { // && isDicomRenderer(this.renderer)) {
            t = estimateTranslationScalingRotation(domain, range);
        } else {
            t = estimateTranslationScaling(domain, range);
        }
        return t.multiplyBy(transform);
    }

    private async startLocation(id: number, x: number, y: number): Promise<void> {
        this.committedTransform = this.commitTransform(this.committedTransform);
        this.transform = this.committedTransform;
        this.locations.set(id, new Location(x, y, x, y));
        this.transform = this.updateTransform(this.committedTransform);
        this.requestFrame();
    }

    private async moveLocation(id: number, x: number, y: number): Promise<void> {
        const p = this.locations.get(id);
        if (p) {
            p.rx = x;
            p.ry = y;
            this.transform = this.updateTransform(this.committedTransform);
            this.requestFrame();
        }
    }

    private endLocation(id: number): void {
        this.committedTransform = this.commitTransform(this.committedTransform);
        this.transform = this.committedTransform;
        this.locations.delete(id);
    }

    private scroll = false;
    private lastTouch?: number

    public readonly canvasTouchstartHandler = (event: TouchEvent): void => {
        if (event.targetTouches.length === event.changedTouches.length) {
            this.scroll = false;
        }

        if (!this.scroll) {
            if (event.targetTouches.length === 1) {
                const t2 = event.timeStamp
                    , t1 = this.lastTouch ?? 0
                    , dt = t2 - t1
                    ;
                this.lastTouch = t2;
                if (dt && dt < 500) {
                    this.lastTouch = undefined;
                    this.toggleFullscreen();
                    event.preventDefault();
                }
            } else {
                event.preventDefault();
            }

            if (this.left) {
                switch (this.left.mode) {
                    case 'pan':
                    case 'zoom':
                    case 'rotate':
                        const rect = this.ui.canvas.getBoundingClientRect();
                        for (let i = 0; i < event.changedTouches.length; ++i) {
                            const t = event.changedTouches[i];
                            this.startLocation(t.identifier, (t.clientX - rect.left) * this.scaleX, (t.clientY - rect.top) * this.scaleY);
                        }
                        break;
                    default:
                        let x = 0, y = 0;
                        const l = event.targetTouches.length;
                        for (let i = 0; i < l; ++i) {
                            const t = event.targetTouches[i];
                            x += t.clientX;
                            y += t.clientY;
                        }
                        x /= l;
                        y /= l;
                        this.left.start({ clientX: x, clientY: y }, this.ui.canvas);
                        break;
                }
            }
        }

        if (event.targetTouches.length > 1) {
            event.preventDefault();
        }
    }

    public readonly windowTouchmoveHandler = (event: TouchEvent): void => {
        this.lastTouch = undefined;
        if (this.fullscreen) {
            event.preventDefault();
        } else if (event.targetTouches.length === 1 && event.changedTouches.length === 1) {
            this.scroll = true;
            this.locations.clear();
        } else {
            event.preventDefault();
        }

        if (!this.scroll && this.left) {
            switch (this.left.mode) {
                case 'pan':
                case 'zoom':
                case 'rotate':
                    const rect = this.ui.canvas.getBoundingClientRect();
                    for (let i = 0; i < event.changedTouches.length; ++i) {
                        const t = event.changedTouches[i];
                        this.moveLocation(t.identifier, (t.clientX - rect.left) * this.scaleX, (t.clientY - rect.top) * this.scaleY);
                    }
                    break;
                default:
                    let x = 0, y = 0;
                    const l = event.targetTouches.length;
                    for (let i = 0; i < l; ++i) {
                        const t = event.targetTouches[i];
                        x += t.clientX;
                        y += t.clientY;
                    }
                    x /= l;
                    y /= l;
                    this.left.step({ clientX: x, clientY: y }, this.ui.canvas);
                    break;
            }

        }
    }

    public readonly windowTouchendHandler = (event: TouchEvent): void => {
        if (!this.scroll && this.left) {
            switch (this.left.mode) {
                case 'pan':
                case 'zoom':
                case 'rotate':
                    for (let i = 0; i < event.changedTouches.length; ++i) {
                        this.endLocation(event.changedTouches[i].identifier);
                    }
                    break;
                default:
                    this.left.end();
                    break;
            }
        }

        if (event.targetTouches.length > 1) {
            event.preventDefault();
        } else if (event.targetTouches.length == 0) {
            this.locations.clear();
        }
        if (event?.target instanceof Node && event?.target === this.ui.canvas) {
            event.preventDefault();
        }
    }

    // Pan Contol

    private static drawRing(cxt: CanvasRenderingContext2D, x: number, y: number, r: number): void {
        cxt.arc(x, y, r - 1, 0, 2 * Math.PI, true);
        cxt.arc(x, y, r + 1, 0, 2 * Math.PI, false);
    }

    private static drawArrow(cxt: CanvasRenderingContext2D): void {
        cxt.moveTo(1, -10);
        cxt.lineTo(1, -15);
        cxt.lineTo(-5, -15);
        cxt.lineTo(0, -20);
        cxt.lineTo(5, -15);
        cxt.lineTo(-1, -15);
        cxt.lineTo(-1, -10);
        cxt.lineTo(1, -10);
    }

    private readonly panStart = async (button: Control, startX: number, startY: number): Promise<void> => {
        this.committedTransform = this.transform;
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            DicomViewer.drawRing(context, 0, 0, 5);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const panStep = async (endX: number, endY: number): Promise<void> => {
            this.transform = Transform.identity.translateBy(endX - startX, endY - startY).multiplyBy(this.committedTransform);
            this.requestFrame();
        }
        const panEnd = async (): Promise<void> => {
            this.committedTransform = this.transform;
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = panStep;
        button.onend = panEnd;
    }

    // Zoom Control

    private readonly zoomStart = async (button: Control, startX: number, startY: number): Promise<void> => {
        this.committedTransform = this.transform;
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            DicomViewer.drawRing(context, 0, 0, 5);
            DicomViewer.drawBlackBox(context, 0, -32.5, 20, 15);
            DicomViewer.drawBlackBox(context, 0, 28.75, 10, 7.5);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI);
            DicomViewer.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const zoomStep = async (_endX: number, endY: number): Promise<void> => {
            const scale = Math.pow(1.01, startY - endY);
            this.transform = Transform.identity.scaleBy(scale, [startX, startY]).multiplyBy(this.committedTransform);
            this.requestFrame();
        }
        const zoomEnd = async (): Promise<void> => {
            this.committedTransform = this.transform;
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = zoomStep;
        button.onend = zoomEnd;
    }

    // Rotate Control

    private static drawCircArrow(cxt: CanvasRenderingContext2D, r: number): void {
        const p = -Math.PI / 4.0;
        const q = Math.PI - p;
        cxt.arc(0, 0, r + 1, p, q, false);
        cxt.lineTo((r + 5) * Math.cos(q), (r + 5) * Math.sin(q));
        cxt.lineTo(r * Math.cos(q) - 5 * Math.sin(q), r * Math.sin(q) + 5 * Math.cos(q));
        cxt.lineTo((r - 5) * Math.cos(q), (r - 5) * Math.sin(q));
        cxt.lineTo((r - 1) * Math.cos(q), (r - 1) * Math.sin(q));
        cxt.arc(0, 0, r - 1, q, p, true);
        cxt.lineTo((r - 5) * Math.cos(p), (r - 5) * Math.sin(p));
        cxt.lineTo(r * Math.cos(p) + 5 * Math.sin(p), r * Math.sin(p) - 5 * Math.cos(p));
        cxt.lineTo((r + 5) * Math.cos(p), (r + 5) * Math.sin(p));
        cxt.lineTo((r + 1) * Math.cos(p), (r + 1) * Math.sin(p));
    }

    private readonly rotateStart = async (button: Control, startX: number, startY: number): Promise<void> => {
        let startAngle: number | null = null;
        let currentAngle: number | null = null;
        this.committedTransform = this.transform;
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            DicomViewer.drawCircArrow(context, 20);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const rotateStep = async (endX: number, endY: number): Promise<void> => {
            const dx = endX - startX;
            const dy = endY - startY;
            const radius = Math.sqrt(dx * dx + dy * dy);
            if (radius > 20.0) {
                const angle = Math.atan2(dy, dx);
                if (startAngle == null) {
                    if (currentAngle == null) {
                        startAngle = angle;
                    } else {
                        startAngle = angle - currentAngle;
                    }
                }
                currentAngle = angle - startAngle;
            } else {
                startAngle = null;
            }
            if (currentAngle != null) {
                this.transform = Transform.identity.rotateBy(currentAngle, [startX, startY]).multiplyBy(this.committedTransform);
                this.requestFrame();
            }
        }
        const rotateEnd = async (): Promise<void> => {
            this.committedTransform = this.transform;
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = rotateStep;
        button.onend = rotateEnd;
    }

    // Params Control

    private static drawWhiteBox(cxt: CanvasRenderingContext2D, x: number, y: number, color: string): void {
        cxt.fillStyle = color;
        cxt.moveTo(x - 10, y - 7.5);
        cxt.lineTo(x + 10, y - 7.5);
        cxt.lineTo(x + 10, y + 7.5);
        cxt.lineTo(x - 10, y + 7.5);
        cxt.lineTo(x - 10, y - 7.5);
    }

    private static drawBlackBox(cxt: CanvasRenderingContext2D, x: number, y: number, w = 20, h = 15): void {
        w /= 2.0;
        h /= 2.0;
        cxt.moveTo(x - w, y - h);
        cxt.lineTo(x + w, y - h);
        cxt.lineTo(x + w, y + h);
        cxt.lineTo(x - w, y + h);
        cxt.lineTo(x - w, y - h);
        w -= 2;
        h -= 2;
        cxt.moveTo(x - w, y - h);
        cxt.lineTo(x - w, y + h);
        cxt.lineTo(x + w, y + h);
        cxt.lineTo(x + w, y - h);
        cxt.lineTo(x - w, y - h);
    }

    private static drawDiagonalBox(cxt: CanvasRenderingContext2D, x: number, y: number): void {
        cxt.moveTo(x - 10, y - 7.5);
        cxt.lineTo(x + 10, y - 7.5);
        cxt.lineTo(x + 10, y + 7.5);
        cxt.lineTo(x - 10, y + 7.5);
        cxt.lineTo(x - 10, y - 7.5);
        cxt.moveTo(x - 8, y - 5.5);
        cxt.lineTo(x - 8, y + 5.5);
        cxt.lineTo(x + 8, y - 5.5);
        cxt.lineTo(x - 8, y - 5.5);
    }

    private readonly paramsStart = async (button: Control, startX: number, startY: number): Promise<void> => {
        if (!(this.renderer && isDicomRenderer(this.renderer))) { return; }
        const width = 2.0 * this.calcWidth();
        const height = 2.0 * this.calcHeight();
        const startB = this.renderer.brightness;
        const startC = this.renderer.contrast;
        let lastX = startX;
        let lastY = startY;
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            context.translate(startX, startY);
            context.scale(this.scaleX, this.scaleY);
            context.lineWidth = 1.5;
            context.strokeStyle = '#000000';
            context.fillStyle = '#0f72c3';
            context.beginPath();
            DicomViewer.drawRing(context, 0, 0, 5);
            DicomViewer.drawWhiteBox(context, 0, -32.5, '#0f72c3');
            DicomViewer.drawBlackBox(context, 0, 32.5);
            DicomViewer.drawDiagonalBox(context, -35, 0);
            DicomViewer.drawWhiteBox(context, 35, 0, '#083962');
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.rotate(Math.PI / 2.0);
            DicomViewer.drawArrow(context);
            context.stroke();
            context.fill();
        };
        this.requestFrame();
        const paramsStep = async (endX: number, endY: number): Promise<void> => {
            if (!(this.renderer && isDicomRenderer(this.renderer))) { return; }
            if (endX !== lastX || endY !== lastY) {
                lastX = endX;
                lastY = endY;
                const dx = ((endX - startX) * this.renderer.img.windowWidth) / (2 * width);
                const dy = ((endY - startY) * this.renderer.img.windowCenter) / height;
                this.renderer.brightness = startB + Math.sign(dy) * Math.pow(Math.abs(dy), 1.2);
                this.renderer.contrast = startC + Math.sign(dx) * Math.pow(Math.abs(dx), 1.2);
                if (this.renderer.contrast < 1.0) {
                    this.renderer.contrast = 1.0;
                }
                this.requestRender();
                this.updateWindow();
            }
        }
        const paramsEnd = async (): Promise<void> => {
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = paramsStep;
        button.onend = paramsEnd;
    }

    // Scrolling

    private drawScroll(cxt: CanvasRenderingContext2D, x: number, y0: number, y1: number, p: number, w = 1, z = 5): void {
        w *= this.scaleY;
        z *= this.scaleY;
        cxt.moveTo(x - w, y0 + w);
        cxt.lineTo(x - w, p + w);
        cxt.lineTo(x - z, p + w);
        cxt.lineTo(x - z, p - w);
        cxt.lineTo(x - w, p - w);
        cxt.lineTo(x - w, y1 - w);
        cxt.lineTo(x + w, y1 - w);
        cxt.lineTo(x + w, p - w);
        cxt.lineTo(x + z, p - w);
        cxt.lineTo(x + z, p + w);
        cxt.lineTo(x + w, p + w);
        cxt.lineTo(x + w, y0 + w);
        cxt.lineTo(x - w, y0 + w);
    }

    private drawText(cxt: CanvasRenderingContext2D, x: number, y: number, t: string): void {
        cxt.strokeStyle = 'black';
        cxt.fillStyle = overlayColour;
        cxt.lineWidth = this.scaleY;
        cxt.lineJoin = "miter";
        cxt.miterLimit = 2;
        cxt.font = `${14 * this.scaleY}px "Noto Sans", Helvetica, Arial, Sans-Serif`;
        cxt.strokeText(t, x, y);
        cxt.fillText(t, x, y);
    }

    private readonly scrollStart = async (button: Control, startX: number, startY: number): Promise<void> => {
        if (!(this.renderer && this.renderer.img.frameCount > 1)) {
            return;
        }
        const startI = this.renderer.index;
        const scale = 2 * this.renderer.img.frameCount / (this.ui.canvas as HTMLCanvasElement).height;
        this.postFrame = (context: CanvasRenderingContext2D): void => {
            if (!(this.renderer && this.renderer.img.frameCount > 1)) {
                return;
            }
            const y0 = startY + ((this.renderer.img.frameCount - 1) - startI) / scale;
            const y1 = startY - startI / scale;
            const p = startY + (this.renderer.index - startI) / scale;

            //context.setTransform(1, 0, 0, 1, 0, 0);
            context.lineWidth = this.scaleY;
            context.strokeStyle = 'black';
            context.fillStyle = overlayColour;
            context.textBaseline = 'middle';
            context.beginPath();
            this.drawScroll(context, startX, y0, y1, p);
            context.stroke();
            context.fill();
            this.drawText(context, startX + 10 * this.scaleX, y0, this.renderer.img.frameCount.toString());
            this.drawText(context, startX + 10 * this.scaleX, y1, '1');
            if (this.renderer.index > 0 && this.renderer.index < this.renderer.img.frameCount - 1) {
                this.drawText(context, startX + 10 * this.scaleX, p, (this.renderer.index + 1).toString());
            }
        };
        this.requestFrame();
        const scrollStep = async (_endX: number, endY: number): Promise<void> => {
            if (this.renderer && this.renderer.img.frameCount > 1) {
                const w = this.renderer.index;
                const z = this.renderer.img.frameCount;
                let j = Math.round(startI + (endY - startY) * scale);
                while (j < 0) {
                    j += z;
                }
                while (j >= z) {
                    j -= z;
                }
                if (j !== w) {
                    this.renderer.index = j;
                    this.requestRender();
                    this.updateScroll();
                }
            }
        }
        const scrollEnd = async (): Promise<void> => {
            button.onstep = undefined;
            button.onend = undefined;
            this.postFrame = undefined;
            this.requestFrame();
        }
        button.onstep = scrollStep;
        button.onend = scrollEnd;
    };

    private getMeasure(p: {x: number, y: number, t: Transform, pixelScale: number}, mode?: string): {measure: Measure, new: boolean} {
        const measures = this.measures.get(this.renderer?.index ?? 1);
        if (measures) {
            for (const measure of measures) {
                if (measure.select(p)) {
                    return {measure, new: false};
                }
            }
        }
        const args: MeasureArgs = {};
        if (isDicomRenderer(this.renderer)) {
            args.sx = this.renderer.img.pixelSpacing?.[0];
            args.sy = this.renderer.img.pixelSpacing?.[1];
        }
        switch (mode) {
            default:
            case 'measure':
                return {measure: new Linear(args), new: true};
            case 'ellipse':
                return {measure: new Ellipse(args), new: true};
            case 'rectangle':
                return {measure: new Rectangle(args), new: true};
        }
    }

    private readonly measureStart = async (button: Control, x: number, y: number): Promise<void> => {
        const t = this.transform.multiplyBy(this.scaleTransform);
        const {measure: lm, new: newMeasure} = this.getMeasure({x, y, t, pixelScale: this.scaleY}, button.mode);
        const u = t.inverse().apply({x, y})
        if (newMeasure) {
            lm.origin(u);
            lm.point(u);
            const index = this.renderer?.index ?? 1
            const measures = this.measures.get(index);
            if (measures) {
                measures.push(lm);
            } else {
                this.measures.set(index, [lm]);
            }
            this.requestFrame();
        }
        const measureStep = async (x: number, y: number): Promise<void> => {
            const t = this.transform.multiplyBy(this.scaleTransform).inverse();
            const u = t.apply({x, y});
            lm.point(u);
            this.requestFrame();
        };
        const measureEnd = async (): Promise<void> => {
            if (lm.size() <= 0) {
                this.measures.get(this.renderer?.index ?? 1)?.pop();
                this.requestFrame();
            }
            button.onstep = undefined;
            button.onend = undefined;
        };
        button.onstep = measureStep;
        button.onend = measureEnd;
    };

    // Control Mode

    private hideDisable(x: string): void {
        const pos = findOption(this.ui.control, x);
        if (pos != null) {
            const opt = this.ui.control.options[pos];
            opt.hidden = true;
            opt.disabled = true;
        }
    }

    private updateScroll(): void {
        if (this.renderer && (this.renderer.img.frameCount > 1)) {
            const pos = findOption(this.ui.control, 'scroll');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent = 'scroll (' +
                        (this.renderer.index + 1) + '/' +
                        this.renderer.img.frameCount + ') [s]';
                }
            }
        } else {
            this.hideDisable('scroll');
        }
    }

    private updateWindow(): void {
        if (this.renderer && isDicomRenderer(this.renderer)) {
            const pos = findOption(this.ui.control, 'window');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent = 'window (' +
                        Math.round(this.renderer.brightness) + ' \u00b1 ' +
                        (Math.round(this.renderer.contrast) / 2) + ') [w]';
                }
            }
        } else {
            this.hideDisable('window');
        }
    }

    private updateNotes(): void {
        if (this.renderer && isDicomRenderer(this.renderer)) {
            const pos = findOption(this.ui.control, 'notes');
            if (pos != null) {
                const sel = this.ui.control.options[pos].firstChild;
                if (sel) {
                    sel.textContent =
                        '[modality = ' + (this.renderer.img.modality) + ']' +
                        '[size = ' + (Math.ceil(this.renderer.img.frames[this.renderer.index].dataSize / 104851) / 10) + ']';
                }
            }
        } else {
            this.hideDisable('notes');
        }
    }

    private async upDown(x: number): Promise<void> {
        const t = this.ui.control;
        switch (t.options[t.selectedIndex].value) {
            case 'pan':
                this.committedTransform = this.transform;
                this.transform = Transform.identity.translateBy(0, x).multiplyBy(this.committedTransform);
                this.requestFrame();
                break;
            case 'zoom': {
                const canvas = this.ui.canvas;
                this.committedTransform = this.transform;
                this.transform = Transform.identity.scaleBy((x > 0) ? (1.01 ** x) : (0.99 ** -x),
                    [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                this.requestFrame();
                break;
            }
            case 'rotate': {
                const canvas = this.ui.canvas;
                this.committedTransform = this.transform;
                this.transform = Transform.identity.rotateBy(0.01 * x,
                    [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                this.requestFrame();
                break;
            }
            case 'scroll':
                if (this.renderer && this.renderer.img.frameCount > 1) {
                    const z = this.renderer.img.frameCount;
                    if (z > 0) {
                        let j = (this.renderer.index) + x;
                        while (j < 0) {
                            j += z;
                        }
                        while (j >= z) {
                            j -= z;
                        }
                        if (j != this.renderer.index) {
                            this.renderer.index = j;
                            this.requestRender();
                            this.updateScroll();
                        }
                    }
                }
                break;
            case 'window':
                if (this.renderer && isDicomRenderer(this.renderer)) {
                    this.renderer.brightness + x;
                    this.requestRender();
                    this.updateWindow();
                }
                break;
        }
    }

    public readonly canvasKeydownHandler = async (event: KeyboardEvent): Promise<void> => {
        const n = (event.target as HTMLElement).tagName;
        if (n === 'INPUT' || n === 'TEXTAREA' || this.renderer == null) {
            // catch all keyboard input unless it comes from
            // and INPUT or TEXTAREA.
            return;
        }
        const t = this.ui.control;
        switch (event.key.toLowerCase()) {
            case 'p': {
                event.preventDefault();
                const pos = findOption(t, 'pan');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'z': {
                event.preventDefault();
                const pos = findOption(t, 'zoom');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'r': {
                event.preventDefault();
                const pos = findOption(t, 'rotate');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 's': {
                event.preventDefault();
                const pos = findOption(t, 'scroll');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'w': {
                event.preventDefault();
                const pos = findOption(t, 'window');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'a': {
                event.preventDefault();
                const pos = findOption(t, 'abdomen');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'u': {
                event.preventDefault();
                const pos = findOption(t, 'pulmonary');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'b': {
                event.preventDefault();
                const pos = findOption(t, 'brain');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'o': {
                event.preventDefault();
                const pos = findOption(t, 'bone');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'm': {
                event.preventDefault();
                const pos = findOption(t, 'measure');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'l': {
                event.preventDefault();
                const pos = findOption(t, 'ellipse');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'c': {
                event.preventDefault();
                const pos = findOption(t, 'rectangle');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'e': {
                event.preventDefault();
                const pos = findOption(t, 'reset');
                if (pos != null) {
                    t.selectedIndex = pos;
                    this.controlChangeHandler();
                }
                break;
            }
            case 'escape': {
                event.preventDefault();
                if (!this.args.getNavigating()) {
                    setImmediate((): Promise<void> => this.destroy());
                } else {
                    this.toggleFullscreen(true);
                    this.scaleToFit();
                }
                break;
            }
            case 'enter':
                event.preventDefault();
                t.focus();
                break;
            case 'arrowup': {
                event.preventDefault();
                await this.upDown(1);
                break;
            }
            case 'arrowdown': {
                event.preventDefault();
                await this.upDown(-1);
                break;
            }
            case 'arrowleft': {
                event.preventDefault();
                switch (t.options[t.selectedIndex].value) {
                    case 'pan':
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.translateBy(-1, 0).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    case 'zoom': {
                        const canvas = this.ui.canvas;
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.scaleBy(0.99,
                            [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    }
                    case 'rotate': {
                        const canvas = this.ui.canvas;
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.rotateBy(-0.01,
                            [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    }
                    case 'scroll':
                        if (this.renderer.img.frameCount > 1) {
                            const z = this.renderer.img.frameCount;
                            if (z > 0) {
                                let j = (this.renderer.index) + 1;
                                while (j >= z) {
                                    j -= z;
                                }
                                if (j != this.renderer.index) {
                                    this.renderer.index = j;
                                    this.requestRender();
                                    this.updateScroll();
                                }
                            }
                        }
                        break;
                    case 'window':
                        if (isDicomRenderer(this.renderer)) {
                            --this.renderer.contrast;
                            this.requestRender();
                            this.updateWindow();
                        }
                        break;
                }
                break;
            }
            case 'arrowright': {
                event.preventDefault();
                switch (t.options[t.selectedIndex].value) {
                    case 'pan':
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.translateBy(1, 0).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    case 'zoom': {
                        const canvas = this.ui.canvas;
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.scaleBy(1.01,
                            [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    }
                    case 'rotate': {
                        const canvas = this.ui.canvas;
                        this.committedTransform = this.transform;
                        this.transform = Transform.identity.rotateBy(0.01,
                            [canvas.width / 2, canvas.height / 2]).multiplyBy(this.committedTransform);
                        this.requestFrame();
                        break;
                    }
                    case 'scroll':
                        if (this.renderer.img.frameCount > 1) {
                            const z = this.renderer.img.frameCount;
                            if (z > 0) {
                                let j = (this.renderer.index) - 1;
                                while (j < 0) {
                                    j += z;
                                }
                                if (j != this.renderer.index) {
                                    this.renderer.index = j;
                                    this.requestRender();
                                    this.updateScroll();
                                }
                            }
                        }
                        break;
                    case 'window':
                        if (isDicomRenderer(this.renderer)) {
                            ++this.renderer.contrast;
                            this.requestRender();
                            this.updateWindow();
                        }
                        break;
                }
                break;
            }
        }
    };


    public readonly controlChangeHandler = async (event?: Event): Promise<void> => {
        if (this.renderer == null) {
            return;
        }
        const t = this.ui.control;
        switch ((t.options[t.selectedIndex]).value) {
            case 'pan': {
                this.left.mode = 'pan';
                this.left.onstart = this.panStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'zoom': {
                this.left.mode = 'zoom';
                this.left.onstart = this.zoomStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'rotate': {
                this.left.mode = 'rotate';
                this.left.onstart = this.rotateStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'scroll': {
                this.left.mode = 'scroll';
                this.left.onstart = this.scrollStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'window': {
                this.left.mode = 'window';
                this.left.onstart = this.paramsStart;
                this.selectedControl = t.selectedIndex;
                this.requestRender();
                this.updateWindow();
                break;
            }
            case 'abdomen': {
                if (isDicomRenderer(this.renderer)) {
                    this.left.mode = 'window';
                    this.left.onstart = this.paramsStart;
                    const pos = findOption(t, 'window');
                    if (pos != null) {
                        t.selectedIndex = pos;
                    }
                    this.renderer.brightness = 150;
                    this.renderer.contrast = 500;
                    this.requestRender();
                    this.updateWindow();
                }
                break;
            }
            case 'pulmonary': {
                if (isDicomRenderer(this.renderer)) {
                    this.left.mode = 'window';
                    this.left.onstart = this.paramsStart;
                    const pos = findOption(t, 'window');
                    if (pos != null) {
                        t.selectedIndex = pos;
                    }
                    this.renderer.brightness = -500;
                    this.renderer.contrast = 1500;
                    this.requestRender();
                    this.updateWindow();
                }
                break;
            }
            case 'brain': {
                if (isDicomRenderer(this.renderer)) {
                    this.left.mode = 'window';
                    this.left.onstart = this.paramsStart;
                    const pos = findOption(t, 'window');
                    if (pos != null) {
                        t.selectedIndex = pos;
                    }
                    this.renderer.brightness = 40;
                    this.renderer.contrast = 80;
                    this.requestRender();
                    this.updateWindow();
                }
                break;
            }
            case 'bone': {
                if (isDicomRenderer(this.renderer)) {
                    this.left.mode = 'window';
                    this.left.onstart = this.paramsStart;
                    const pos = findOption(t, 'window');
                    if (pos != null) {
                        t.selectedIndex = pos;
                    }
                    this.renderer.brightness = 570;
                    this.renderer.contrast = 3000;
                    this.requestRender();
                    this.updateWindow();
                }
                break;
            }
            case 'measure': {
                this.left.mode = 'measure';
                this.left.onstart = this.measureStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'ellipse': {
                this.left.mode = 'ellipse';
                this.left.onstart = this.measureStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'rectangle': {
                this.left.mode = 'rectangle';
                this.left.onstart = this.measureStart;
                this.selectedControl = t.selectedIndex;
                break;
            }
            case 'reset': {
                if (this.selectedControl != null) {
                    t.selectedIndex = this.selectedControl;
                }
                this.resetView();
                this.scaleToFit();
                this.requestRender();
                this.updateScroll();
                this.updateWindow();
                break;
            }
            case 'close': {
                if (!this.args.getNavigating()) {
                    setImmediate((): Promise<void> => this.destroy());
                } else if (this.selectedControl != null) {
                    t.selectedIndex = this.selectedControl;
                }
                break;
            }
        }
        t.blur();
        this.ui.canvas.focus();
        if (event) {
            event.preventDefault();
        }
    };

    public readonly closeClickHandler = (/*event: Event*/): void => {
        if (!this.args.getNavigating()) {
            setImmediate((): Promise<void> => this.destroy());
        }
    };

    public readonly prevClickHandler = () => {
        if (this.renderer && this.renderer.img.frameCount > 1) {
            if (this.renderer.index > 0) {
                --this.renderer.index;
                this.requestRender();
                this.updateScroll();
            }
        }
    }

    public readonly nextClickHandler = () => {
        if (this.renderer && this.renderer.img.frameCount > 1) {
            if (this.renderer.index < this.renderer.img.frameCount - 1) {
                ++this.renderer.index;
                this.requestRender();
                this.updateScroll();
            }
        }
    }

    public readonly expandClickHandler = () => {
        if (this.fullscreen) {
            replaceIcon(this.ui.expandIcon, faExpand);
        } else {
            replaceIcon(this.ui.expandIcon, faCompress);
        }
        this.toggleFullscreen();
        this.scaleToFit();
    }

    public readonly resetClickHandler = async (): Promise<void> => {
        this.resetView();
        this.scaleToFit();
        this.requestRender();
        this.updateScroll();
        this.updateWindow();
    };

    private resetView(): void {
        this.measures.clear();
        this.committedTransform = Transform.identity;
        this.transform = Transform.identity;
        if (this.renderer) {
            if (this.renderer.img.frameCount > 1) {
                this.renderer.index = 0;
            }
            if (isDicomRenderer(this.renderer)) {
                this.renderer.contrast = this.renderer.img.windowWidth;
                this.renderer.brightness = this.renderer.img.windowCenter;
            }
        }
    }

    //------------------------------------------------------------------------
    // Window Resizing

    private getPixelScale(): void {
        this.scaleX = window.devicePixelRatio || 1.0;
        this.scaleY = window.devicePixelRatio || 1.0;
        if (window.visualViewport && window.visualViewport.scale != 1) {
            this.scaleX *= window.visualViewport.scale;
            this.scaleY *= window.visualViewport.scale;
        } else if (detectBrowser() == 'safari/webkit') {
            this.scaleX *= document.documentElement.clientWidth / window.innerWidth;
            this.scaleY *= document.documentElement.clientHeight / window.innerHeight;
            /*
            if (window.outerWidth && window.outerHeight) {
                this.scaleX *= window.outerWidth / window.innerWidth;
                this.scaleY *= window.outerHeight / window.innerHeight;
            } else { // no outer dimensions = mobile
                let sw = screen.width;
                let sh = screen.height;
                if ((screen.width > screen.height) != (window.innerWidth > window.innerHeight)) {
                    sw = screen.height;
                    sh = screen.width;
                }
                this.scaleX *= sw / window.innerWidth;
                this.scaleY *= sh / window.innerHeight;
            }
            */
        }

        //this.scaleX = Math.min(this.scaleX, this.scaleY);
        //this.scaleY = this.scaleX;

        this.left.scaleX = this.scaleX;
        this.left.scaleY = this.scaleY;
        this.right.scaleX = this.scaleX;
        this.right.scaleY = this.scaleY;
    }

    private calcWidth(): number {
        return this.ui.canvasPanel.scrollWidth;
        /*if (this.fullscreen) {
            return this.args.window.innerWidth || this.args.window.document.documentElement.clientWidth ||
                this.args.window.document.body.clientWidth;
        } else if (this.args.parent) {
            return this.args.parent.clientWidth;// - 3.2 * this.rem;
        } else {
            throw 'CALC_WIDTH';
        }*/
    }

    private calcHeight(): number {
        return this.ui.canvasPanel.scrollHeight;
        /*if (this.fullscreen) {
            return this.args.window.innerHeight || this.args.window.document.documentElement.clientHeight ||
                this.args.window.document.body.clientHeight;
        } else if (this.args.sizeReference) {
            return this.args.sizeReference.clientHeight;// - 3.2 * this.rem;
        } else {
            throw 'CALC_HEIGHT';
        }*/
    }

    private calcRect(): { width: number; height: number } {
        return { width: this.calcWidth(), height: this.calcHeight() };
    }

    private scale(width: number, height: number): void {
        if (this.renderer && this.renderer.img.cols && this.renderer.img.rows) {
            const rows = this.renderer.img.rows
            , cols = this.renderer.img.cols
            , scale = Math.min(width / cols, height / rows)
            ;

            //const x = width - scale * cols;
            //const y = height - scale * rows;

            //this.scaleTransform = Transform.identity.scaleBy(scale).translateBy(x/2, y/2);
            this.scaleTransform = Transform.identity.scaleBy(scale);

            //this.scaleTransform = Transform.identity.scaleBy(scale).multiplyBy(this.transform.inverse());
            //this.scaleTransform = Transform.identity.translateBy(cols/2, rows/2).scaleBy(scale)).multiplyBy(this.transform.inverse());
        } else {
            console.error('SCALE FAIL', this.renderer, this.renderer?.img.cols, this.renderer?.img.rows);
        }
    }

    /*
    private scaleToFit(): void {
        if (this.renderer && this.renderer.img.cols != null && this.renderer.img.rows != null) {
            const width = this.calcWidth() * this.scaleX
                , height = this.calcHeight() * this.scaleY
                , rows = this.renderer.img.rows
                , cols = this.renderer.img.cols
                , scale = Math.min(width / cols, height / rows)
                ;

            this.scaleTransform = Transform.identity.scaleBy(scale, [width / 2, height / 2]);
        }
    }
    */

    private scaleToFit(): void {
        this.getPixelScale();
        const r = this.calcRect();
        const width = Math.floor(r.width * this.scaleX);
        const height = Math.floor(r.height * this.scaleY);
        this.scale(width, height);
    }

    private resizeCanvas(force: boolean, width?: number, height?: number): boolean {
        if (!(this.renderer && this.renderer.img.rows && this.renderer.img.cols)) {
            console.error('RENDERER IMAGE SIZE UNINITIALISED');
            return false;
        }

        if (width === undefined || height === undefined) {
            const r = this.calcRect();
            width = r.width;
            height = r.height;
        }

        if (this.renderer instanceof PdfRenderer && this.renderer.viewport) {
            console.debug('PDF VIEW SIZING ENABLED', this.renderer.viewport.width, this.renderer.viewport.height);
            height = width * this.renderer.viewport.height / this.renderer.viewport.width;
            if (this.ui.canvasPanel.clientHeight !== height) {
                console.debug('CANVAS PANEL RESIZED', height);
                this.ui.canvasPanel.style.height = `${Math.round(height)}px`;
            }
        }

        this.getPixelScale();
        const innerWidth = Math.floor(width * this.scaleX);
        const innerHeight = Math.floor(height * this.scaleY);

        console.log(this.ui.canvas.width + ' x ' + this.ui.canvas.height + ' = ' + innerWidth + ' x ' + innerHeight);
        if (force ||
            this.ui.canvas.width !== innerWidth ||
            this.ui.canvas.height !== innerHeight
        ) {
            //force && console.log('FORCE');
            this.ui.canvas.style.width = `${width}px`;
            this.ui.canvas.style.height = `${height}px`;
            this.ui.canvas.width = innerWidth;
            this.ui.canvas.height = innerHeight;
            this.scale(innerWidth, innerHeight);
            return true;
        }
        return false;
    }

    private async resize(force = false, width?: number, height?: number): Promise<void> {
        //console.log('RESIZE');
        if (this.resizeCanvas(force, width, height)) {
            await this.animationFrame();
        }
    }

    /*private scrollToImage(): void {
        const parent = this.fullscreen ? this.args.fullscreenParent : this.args.scrollContainer
        if (parent && parent.scrollHeight > parent.clientHeight) {
            //this.args.parent.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop);
            parent.scrollTop = this.ui.canvasPanel.offsetTop;
        } else if (parent && parent.parentElement && parent.parentElement.scrollHeight > parent.parentElement.clientHeight) {
            //this.args.parent.parentElement.scrollTo(0, (this.ui.canvas_panel as HTMLCanvasElement).offsetTop - 1.6 * this.rem);
            parent.parentElement.scrollTop = this.ui.canvasPanel.offsetTop - 1.6 * this.rem;
        }
    }*/

    public readonly handleResize = (entries: ResizeObserverEntry[]): void => {
        const l = entries.length;
        if (l > 0) {
            const {width, height} = entries[l-1].contentRect;
            //console.log('NEWSIZE', width, height);
            this.resize(false, Math.floor(width), Math.floor(height));
        }
    }

    public disabled(isDisabled: boolean): void {
        console.log('IMAGE_VIEWER_DISABLED', isDisabled);
        this.ui.close.disabled = isDisabled;
    }

    public async setDicom(renderer?: Renderer): Promise<void> {
        //try {
        if (this.requestedAnimationFrame !== undefined) {
            window.cancelAnimationFrame(this.requestedAnimationFrame);
        }
        this.measures.clear();
        if (renderer === this.renderer) {
            return;
        }
        if (this.renderer) {
            this.renderer.destroy();
        }
        this.renderer = renderer;
        if (!renderer) {
            this.requestFrame();
            return;
        }
        //console.log('SET DICOM ' + renderer.img.frames.toString());
        this.postFrame = undefined;
        this.ui.progress.style.width = (100.0 / (renderer.img.frameCount + 1)) + "%"
        if (renderer.img.caption) {
            this.ui.tspan.innerHTML = renderer.img.caption;
        } else {
            this.ui.tspan.innerHTML = '&nbsp;'
        }
        //if (isDicomRenderer(renderer)) { // } && renderer.img.windowWidth <= 0) {
            //dicomMinMax(renderer.img, renderer.index);
            //console.debug(`SET_DICOM_WINDOW ${renderer.img.windowCenter}~${renderer.img.windowWidth}`);
            //renderer.brightness = renderer.img.windowCenter;
            //renderer.contrast = renderer.img.windowWidth;
        //}
        const sel = this.ui.control;
        removeChildren(sel);
        /*if (this.args && this.args.noMouse) {
            if (isDicomRenderer(renderer) || renderer.img.frames > 1) {
                mkNode('text', { text: 'pan/zoom/rotate [p]', parent: mkNode('option', { parent: sel, attrib: { value: 'pan' } }) });
                this.ui.control.style.display = 'block';
                this.ui.reset.style.display = 'none';
            } else {
                this.ui.control.style.display = 'none';
                this.ui.reset.style.display = 'block';
            }
        } else {
        */
            mkNode('text', { text: 'pan [p]', parent: mkNode('option', { parent: sel, attrib: { value: 'pan' } }) });
            mkNode('text', { text: 'zoom [z]', parent: mkNode('option', { parent: sel, attrib: { value: 'zoom' } }) });
            mkNode('text', { text: 'rotate [r]', parent: mkNode('option', { parent: sel, attrib: { value: 'rotate' } }) });
            this.ui.control.hidden = false;
            this.ui.reset.hidden = true;
        //}
        //console.log('IMG', renderer.img);
        this.ui.expand.hidden = this.args.parent === this.args.fullscreenParent;
        if (renderer.img.frameCount > 1) {
            this.ui.next.hidden = false;
            this.ui.prev.hidden = false;
            mkNode('text', { text: 'scroll [s]', parent: mkNode('option', { parent: sel, attrib: { value: 'scroll' } }) });
        } else {
            this.ui.next.hidden = true;
            this.ui.prev.hidden = true;
        }
        this.updateScroll();
        if (isDicomRenderer(renderer)) {
            mkNode('text', { text: 'window [w]', parent: mkNode('option', { parent: sel, attrib: { value: 'window' } }) });
            if (renderer.img.modality === 'CT') {
                mkNode('text', { text: 'window = abdomen [a]', parent: mkNode('option', { parent: sel, attrib: { value: 'abdomen' } }) });
                mkNode('text', { text: 'window = pulmonary [u]', parent: mkNode('option', { parent: sel, attrib: { value: 'pulmonary' } }) });
                mkNode('text', { text: 'window = brain [b]', parent: mkNode('option', { parent: sel, attrib: { value: 'brain' } }) });
                mkNode('text', { text: 'window = bone [o]', parent: mkNode('option', { parent: sel, attrib: { value: 'bone' } }) });
            }
            this.updateWindow();
            mkNode('text', { text: 'measure [m]', parent: mkNode('option', { parent: sel, attrib: { value: 'measure' } }) });
            mkNode('text', { text: 'ellipse [l]', parent: mkNode('option', { parent: sel, attrib: { value: 'ellipse' } }) });
            mkNode('text', { text: 'rectangle [c]', parent: mkNode('option', { parent: sel, attrib: { value: 'rectangle' } }) });
            mkNode('text', { text: 'reset [e]', parent: mkNode('option', { parent: sel, attrib: { value: 'reset' } }) });
            mkNode('text', { text: '-', parent: mkNode('option', { parent: sel, attrib: { value: 'notes', disabled: 'true' } }) });
            this.updateNotes();
        } else { // if (!(this.args && this.args.noMouse)) {
            //mkNode('text', { text: 'measure [m]', parent: mkNode('option', { parent: sel, attrib: { value: 'measure' } }) });
            //mkNode('text', { text: 'ellipse [l]', parent: mkNode('option', { parent: sel, attrib: { value: 'ellipse' } }) });
            //mkNode('text', { text: 'rectangle [c]', parent: mkNode('option', { parent: sel, attrib: { value: 'rectangle' } }) });
            mkNode('text', { text: 'reset [e]', parent: mkNode('option', { parent: sel, attrib: { value: 'reset' } }) });
        }
        if (renderer.img.frameCount > 1) {
            this.left.mode = 'scroll';
            this.left.onstart = this.scrollStart;
            const pos = findOption(sel, 'scroll');
            if (pos != null) {
                this.selectedControl = pos;
                sel.selectedIndex = pos;
            }
        } else {
            this.left.mode = 'pan';
            this.left.onstart = this.panStart;
            const pos = findOption(sel, 'pan');
            if (pos != null) {
                this.selectedControl = pos;
                sel.selectedIndex = pos;
            }
        }
        //this.ui.title.style.display = 'block';
        //this.ui.canvasPanel.style.display = 'block';
        //this.resizeCanvas(true);
        //this.scrollToImage();
        //this.ui.canvas.focus();
        await renderer.load(
            this.args.getImageBegin,
            this.args.getImageFrame,
            this.args.getImageEnd,
            async () => {
                //this.ui.title.style.display = 'block';
                this.ui.canvasPanel.style.display = 'flex';
                this.resizeCanvas(true);
                this.scaleToFit();
                //scrollRangeIntoView(this.ui.canvasPanel);
                //this.scrollToImage();
                this.ui.canvas.focus();
                this.requestRender()
                scrollRangeIntoView(this.args.parent);
            },
            (p: number) => {
                this.ui.progress.style.width = p + '%';
            }
        )
        //this.resizeCanvas(true);
        //this.scrollToImage();
        //this.ui.canvas.focus();
        await wait(300);
        this.ui.progress.style.width = '0%';
    }

    // Clean up

    public async destroy(): Promise<void> {
        //console.log('DESTROY');
        if (this.requestedAnimationFrame !== undefined) {
            window.cancelAnimationFrame(this.requestedAnimationFrame);
        }
        if (this.renderer) {
            this.renderer.destroy();
        }
        this.renderer = undefined;
        this.left.clear();
        this.right.clear();
        this.fullscreen = false;
        removeNode(this.ui.viewer);
        //removeNode(this.ui.canvasPanel);
        //removeNode(this.ui.title);
        if (this.visibilityChange) {
            this.args.window.removeEventListener(this.visibilityChange, this.visibilityHandler);
        }
        const passive: AddEventListenerOptions & EventListenerOptions = { passive: true };
        const notPassive: AddEventListenerOptions & EventListenerOptions = { passive: false };
        this.args.window.removeEventListener('beforeunload', this.unloadHandler);
        this.ui.canvas.removeEventListener('click', this.canvasClickHandler);
        this.ui.canvas.removeEventListener('dblclick', this.canvasDblclickHandler);
        this.ui.canvas.removeEventListener('mousedown', this.canvasMousedownHandler);
        this.ui.canvas.removeEventListener('touchstart', this.canvasTouchstartHandler, notPassive);
        //this.ui.canvas.removeEventListener('wheel', this.canvasMouseWheelHandler, notPassive);
        this.ui.control.removeEventListener('change', this.controlChangeHandler);
        this.ui.close.removeEventListener('click', this.closeClickHandler, passive);
        this.ui.prev.addEventListener('click', this.prevClickHandler, passive);
        this.ui.next.addEventListener('click', this.nextClickHandler, passive);
        this.ui.expand.addEventListener('click', this.expandClickHandler, passive);
        this.ui.reset.removeEventListener('click', this.resetClickHandler, passive);
        this.ui.canvas.removeEventListener('keydown', this.canvasKeydownHandler, notPassive);
        this.args.window.removeEventListener('contextmenu', this.canvasContextmenuHandler);
        this.args.window.removeEventListener('mousemove', this.windowMousemoveHandler);
        this.args.window.removeEventListener('touchmove', this.windowTouchmoveHandler, notPassive);
        this.args.window.removeEventListener('mouseup', this.windowMouseupHandler);
        this.args.window.removeEventListener('touchend', this.windowTouchendHandler, notPassive);
        //this.args.window.removeEventListener('resize', this.windowResizeHandler);
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        if (typeof this.onclose === 'function') {
            this.onclose();
            this.onclose = undefined;
        }
        //console.log('DESTROY DONE');
    }
}


