import { mkNode, scrollRangeIntoView, removeNode } from 'utils';
import { LocalData } from 'exam-service';
import { Question, QuestionContext, QuestionManifest, QuestionBase, QuestionArgs, Expr, registerAnswerType, Layout, AnswerValue, AnswerKey } from 'question-base';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Lightbox } from 'lightbox';
import { configDngrPress, configSafePress } from 'exam-accessibility';

function isBoolean(x: unknown): x is boolean|null {
    return typeof x === 'boolean' || x === 'null';
}

/** Dropdown question textarea UI */
class QuestionBoolean extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    private trueButton: HTMLButtonElement;
    private falseButton: HTMLButtonElement;
    private answer: boolean|null;
    private updateVisibility: () => void;

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility:  () => void,
        visibilityExpression?: Expr,
     }) {
        super({...args, showFlag: true, layout: Layout.CompactFloat});
        const {indent, updateVisibility, visibilityExpression} = args;
        const indentRem = String(1.6 * (indent ?? 0) + 1.6);
        this.label.style.paddingLeft = `${indentRem}rem`; 
        this.answerItem = mkNode('div', {className: 'answer-item', style: {paddingTop: '0', paddingLeft: '8px'}, parent: this.column});
        this.trueButton = mkNode('button', {className: 'boolean-button ' + configSafePress, parent: this.answerItem, tabindex: 0, disabled: true, children: [
            mkNode('icon', {icon: faCheck}),
        ], attrib: {'data-value': 'yes'}});
        this.falseButton = mkNode('button', {className: 'boolean-button ' + configDngrPress, parent: this.answerItem, tabindex: 0, disabled: true, children: [
            mkNode('icon', {icon: faTimes}),
        ], attrib: {'data-value': 'no'}});
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        this.answer = null;
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            if (response && isBoolean(response.answer)) {
                this.answer = response.answer;
            }
            this.update();
        } catch(e) {
            console.error(String(e));
        }
    }

    public updateDisable() {
        super.updateDisable();
        const disabled = this.isDisabled();
        this.trueButton.disabled = disabled;
        this.falseButton.disabled = disabled;
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.trueButton.addEventListener('click', this.handleTrue);
        this.falseButton.addEventListener('click', this.handleFalse);
    }

    /** Get the answer value */
    public getValue(): string {
        return String(this.answer ?? '');
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.trueButton.removeEventListener('click', this.handleTrue);
        this.falseButton.removeEventListener('click', this.handleFalse);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    private update() {
        if (this.answer === true) {
            this.trueButton.setAttribute('aria-pressed', 'true');
            this.falseButton.setAttribute('aria-pressed', 'false');
        } else if (this.answer === false) {
            this.trueButton.setAttribute('aria-pressed', 'false');
            this.falseButton.setAttribute('aria-pressed', 'true');
        } else {
            this.trueButton.setAttribute('aria-pressed', 'false');
            this.falseButton.setAttribute('aria-pressed', 'false');
        }
        this.updateVisibility();
    }

    public getAnswer(): AnswerKey & AnswerValue {
        return {qno: this.qno, ano: this.ano, answer: this.answer};
    }
    
    private async submit() {
        try {
            await this.context.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.answer});
        } catch(err) {
            console.error(String(err));
        } finally {
            this.updateVisibility();
        }
    }


    private handleTrue = async (): Promise<void> => {
        if (!(this.isLoading || this.isReadOnly)) {
            if (this.answer === true) {
                this.answer = null;
            } else {
                this.answer = true;
            }
            this.update();
            this.submit();
        }
    }

    private handleFalse = async (): Promise<void> => {
        if (!(this.isLoading || this.isReadOnly)) {
            if (this.answer === false) {
                this.answer = null;
            } else {
                this.answer = false;
            }
            this.update();
            this.submit();
        }
    }
}

registerAnswerType({
    name: 'Boolean',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'boolean';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerDiscrete,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
    ): Question => {
        return new QuestionBoolean({
            updateVisibility,
            context,
            qno,
            ano,
            backendQid: question.manifest.backend_id,
            backendAid: answer.backend_id,
            showNumber: question.manifest.answers.length > 1,
            label: answer.label,
            frag,
            lightbox,
            isRemoteShowHide,
            indent: answer.indent,
            visibilityExpression: answer.visible,
            notes: answer.notes,
            resources: question.answersResources[ano],
            mandatory: answer.mandatory,
            type: answer.type.toLowerCase(),
        });
    }
});
